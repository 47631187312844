import { loadStripe, Stripe } from '@stripe/stripe-js';
import { STRIPE_API_KEY } from '../../api/constants';

const stripePromise = loadStripe(STRIPE_API_KEY);
let stripe: Stripe | null = null;
export async function initStripe() {
  if (stripe) {
    return;
  }
  try {
    stripe = await stripePromise;
  } catch (error) {
    stripe = null;
  }
}

export const GetOrCreateStripeAsync = async function () {
  await initStripe();
  return stripe;
};
