import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const RegistrationLinkComponent = function RegistrationLinkComponent() {
  const { t } = useTranslation();
  return (
    <div className="container">
      {t('registerPage.link.goToRegistration.label')}{' '}
      <Link to="/" className="fw-bold">
        {t('registerPage.link.goToRegistration.text')}
      </Link>
    </div>
  );
};
