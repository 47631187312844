import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const ForgottenPasswordLinkComponent = function ForgottenPasswordLinkComponent() {
  const { t } = useTranslation();
  return (
    <div className="container">
      {t('loginPage.form.link.forgottenPassword.label')}{' '}
      <Link to="/forgotten-password" className="fw-bold">
        {t('loginPage.form.link.forgottenPassword.text')}
      </Link>
    </div>
  );
};
