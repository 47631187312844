import { Link } from 'react-router-dom';

export const ResendRegistrationLinkComponent = function ResendRegistrationLinkComponent() {
  return (
    <div className="container">
      Expired token OR Incomplete registration?{' '}
      <Link to="/resend-registration-email" className="fw-bold">
        Resend verification email.
      </Link>
    </div>
  );
};
