import { Result } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const EmailSentContinueRegistrationComponent: FC<{ email: string }> =
  function EmailSentContinueRegistrationComponent({ email }) {
    const { t } = useTranslation();

    return (
      <Result
        status="success"
        title={`Great. Now we need to verify your email address.
        Check your email,  (Spam/Junk folders too)
        for an email from server@panthertec.net
        and click verify to finish Step 1.`}
        subTitle={
          <a className="centered" href={`mailto:${email}`}>
            {t('resendRegistrationEmailPage.checkYourInbox')}
          </a>
        }
      ></Result>
    );
  };
