import { Button, Form } from 'antd/es';
import { useEffect, useState } from 'react';

import { Spin } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { Server } from '../../api/server-class';
import { IsErrorResDto } from '../../api/shared-types/error-res.dto';
import { FloatInput } from '../../components/input/float-input';
import { ForgottenPasswordLinkComponent } from '../register/components/forgotten-password-link.component';

export const UpdateForgottenPasswordPage = function UpdateForgottenPasswordPage() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: '',
    token: '',
  });
  const [form] = Form.useForm<{ email: string }>();
  const [onError, setOnError] = useState(false);
  const translationPrefix = `updateForgottenPasswordPage`;
  useEffect(() => {
    const obj: any = {};
    const searchParams = new URLSearchParams(location.search);

    searchParams.forEach((value, key) => {
      obj[key] = value;
    });
    setState({ ...obj });
  }, [location]);

  const onFormSubmit = async (value: any) => {
    setLoading(true);
    const response = await Server.PublicForgottenPassword.patch({
      email: state.email,
      token: state.token,
      newPassword: value.password,
      newRepeatedPassword: value.repeatedPassword,
    });
    setLoading(false);
    if (!IsErrorResDto(response)) {
      navigate('/login');
    } else {
      setOnError(true);
    }
  };
  return (
    <>
      <Spin spinning={loading}>
        <h1 className="text-center text-uppercase fw-bold">Update your password.</h1>

        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 24 }} onFinish={onFormSubmit} form={form}>
          <FormItem name="email" initialValue={state.email} hidden>
            <FloatInput label={t('forgottenPasswordPage.form.email.label')} readOnly value={state.email} />
          </FormItem>
          <FormItem>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: t(`${translationPrefix}.form.password.required`) as string },
                {
                  min: 8,
                  message: 'Password must be 8 or more characters',
                },
              ]}
            >
              <FloatInput label={t(`${translationPrefix}.form.password.label`)} required type="password" />
            </Form.Item>

            <FormItem
              name="repeatedPassword"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: `${t(`${translationPrefix}.form.repeatedPassword.required`)}`,
                  type: 'string',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(`${t(`${translationPrefix}.form.repeatedPassword.mustMatch`)}`));
                  },
                }),
              ]}
            >
              <FloatInput type="password" required label={t(`${translationPrefix}.form.repeatedPassword.label`)} />
            </FormItem>
            <Button type="primary" htmlType="submit" loading={loading} block>
              {t(`${translationPrefix}.form.submitButton.label`)}
            </Button>
          </FormItem>
        </Form>
      </Spin>
      <p></p>
      {onError && <ForgottenPasswordLinkComponent />}
    </>
  );
};
