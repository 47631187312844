import { Input } from 'antd';
import { useState } from 'react';

import './float-input.css';

interface Props {
  label?: string | null;
  value?: any;
  placeholder?: string | null;
  type?: string;
  required?: boolean;
  addonBefore?: any;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (e: any) => void;
  maxLength?: number;
  showCount?: boolean;
  className?: string;
}

export const FloatInput = (props: Props) => {
  const [focus, setFocus] = useState(false);
  let {
    label,
    value,
    placeholder,
    type,
    required,
    addonBefore,
    disabled,
    onChange,
    readOnly,
    maxLength,
    showCount,
    className,
  } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';

  const requiredMark = required ? (
    <span className="text-danger" style={{ color: 'red' }}>
      *
    </span>
  ) : null;

  const onChangeProxy: (e: any) => void = (e) => {
    if (type === 'email') {
      e.target.value = e.target?.value?.replace(/ /g, '');
    }
    onChange?.(e);
  };

  return (
    <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      {type === 'password' ? (
        <Input.Password
          className={`float-input ` + (className ?? '')}
          addonBefore={addonBefore}
          onChange={onChangeProxy}
          type={type}
          defaultValue={value}
          style={{ height: 40 }}
          readOnly={readOnly}
          disabled={disabled}
          maxLength={maxLength}
          showCount={showCount}
        ></Input.Password>
      ) : (
        <Input
          className={`float-input ` + (className ?? '')}
          addonBefore={addonBefore}
          onChange={onChangeProxy}
          type={type}
          defaultValue={value}
          style={{ height: 40 }}
          readOnly={readOnly}
          disabled={disabled}
          maxLength={maxLength}
          showCount={showCount}
        />
      )}

      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};
