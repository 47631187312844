import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripePaymentElement } from '@stripe/stripe-js';
import { Grid, Spin, message } from 'antd';

import { Button } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { FormEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { REACT_APP_POST_PAYMENT_URL } from '../../../api/constants';
import { InvoiceData, StripeLineItemsResponse } from '../../../api/public/res/public-stripe-setup-intent-res.dto';
import { SelectedPlan } from '../register.page';
import { GeneralInformationComponent } from './patch-general-information.component';

export const StripeCheckoutComponent: React.FC<{
  plan: SelectedPlan;
  katDevicesAmount: number | null;
  refreshClientSecret: () => void;
  invoiceData: InvoiceData | null;
}> = function StripeCheckoutComponent({ plan, refreshClientSecret, katDevicesAmount, invoiceData }) {
  const stripe = useStripe();
  const elements = useElements();
  const sizing = Grid.useBreakpoint();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const translationPrefix = 'registerPage';
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    // let total = plan.amountInCents;
    // if (katDevicesAmount) {
    //   total += katDevicesAmount * 499 * 100;
    // }

    setTotal(invoiceData?.total ?? 0);
  }, [setTotal, katDevicesAmount, plan.amountInCents]);

  const [isPaymentPending, setPaymentPending] = useState(false);

  const onClick: FormEventHandler<any> = async (e) => {
    try {
      setPaymentPending(true);
      e.preventDefault();
      e.stopPropagation();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: REACT_APP_POST_PAYMENT_URL,
          save_payment_method: true,
        },
      });
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error) {
        if (error.type === 'card_error' || error.type === 'validation_error') {
          message.error(error.message);
        } else {
          message.error('An unexpected error occurred. Please try again');
          refreshClientSecret();
          return;
        }
      }
      if (paymentIntent?.status === 'succeeded') {
        localStorage.removeItem(GeneralInformationComponent.name);
        navigate('/post-registration');
      }
    } finally {
      setPaymentPending(false);
    }
  };

  const onReady = (element: StripePaymentElement) => {
    setIsLoading(false);
  };

  const onLoadError = () => {
    message.error('Error getting payment provider');
    refreshClientSecret();
  };

  const planLineItem = (lineItems: StripeLineItemsResponse[] | undefined) => {
    const licenseLineItem = lineItems?.find((x) => x.description?.includes('License:'));
    if (!licenseLineItem) {
      return <></>;
    }
    const quantity = licenseLineItem.quantity!;
    const description = licenseLineItem.description;
    const amountWithTax = licenseLineItem.amount;
    const amountWithoutTax = licenseLineItem.amountExcludingTax;
    const tax = amountWithoutTax - amountWithTax;
    return (
      <div className="checkout-summary my-2 d-flex justify-space-between">
        <span className="fw-bold">{description}</span>
        <span className="ms-2">
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amountWithoutTax / 100)}
        </span>
      </div>
    );
  };
  const katLineItem = (lineItems: StripeLineItemsResponse[] | undefined) => {
    const katDeviceLineItem = lineItems?.find((x) => x.description?.includes('Additional KAT'));
    if (!katDeviceLineItem) {
      return <></>;
    }
    const quantity = katDeviceLineItem.quantity!;
    const description = katDeviceLineItem.description;
    const amountWithTax = katDeviceLineItem.amount;
    const amountWithoutTax = katDeviceLineItem.amountExcludingTax;
    const tax = amountWithoutTax - amountWithTax;
    return (
      <div className="checkout-summary my-2 d-flex justify-space-between">
        <span className="fw-bold mx-2">
          {quantity} x {description}
        </span>
        <span className="ms-2">
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amountWithoutTax / 100)}
        </span>
      </div>
    );
  };

  const emptyShippingText = (lineItems: StripeLineItemsResponse[] | undefined) => {
    const shippingLineItem = lineItems?.find((x) => x.description?.includes('Shipping'));
    if (!shippingLineItem) {
      return (
        <span>
          <i>
            <b>**International Shipping costs***</b> are not included here. We will contact you at the email you
            provided to discuss your international shipping requirements.
          </i>
        </span>
      );
    }
    return <></>;
  };
  const shippingLineItem = (lineItems: StripeLineItemsResponse[] | undefined) => {
    const shippingLineItem = lineItems?.find((x) => x.description?.includes('Shipping'));
    if (!shippingLineItem) {
      return <></>;
    }
    const quantity = shippingLineItem.quantity!;
    const description = shippingLineItem.description;
    const amountWithTax = shippingLineItem.amount;
    const amountWithoutTax = shippingLineItem.amountExcludingTax;
    const tax = amountWithoutTax - amountWithTax;
    return (
      <div className="checkout-summary my-2 d-flex justify-space-between">
        <span className="fw-bold mx-2">{description}</span>
        <span className="ms-2">
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amountWithTax / 100)}
        </span>
      </div>
    );
  };
  return (
    <>
      <div className="summary-wrapper py-5">
        <h1 className="text-center text-uppercase fw-bold">Step 2: Purchase KAT System.</h1>

        <h2 className="text-center text-uppercase fw-bold">Your order summary:</h2>
        {planLineItem(invoiceData?.lineItems)}
        {katLineItem(invoiceData?.lineItems)}
        {shippingLineItem(invoiceData?.lineItems)}
        <div>
          <div className="checkout-summary my-2 d-flex justify-space-between">
            <span className="fw-bold mx-2">VAT</span>
            <span className="ms-2">
              {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                (invoiceData?.tax ?? 0) / 100,
              )}
            </span>
          </div>
        </div>
        <div className="checkout-summary my-2 d-flex justify-space-between border-top-stripped">
          <span className="fw-bold">Total</span>
          <span className="ms-2">
            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total / 100)}
          </span>
        </div>
        {emptyShippingText(invoiceData?.lineItems)}
      </div>
      <PaymentElement id="payment-element" onReady={onReady} onLoadError={onLoadError} className="my-2" />
      <FormItem wrapperCol={sizing.xs === true ? undefined : { offset: 4, span: 16 }}>
        <Button
          type="primary"
          htmlType="submit"
          className="mt-2"
          style={{ width: '100%' }}
          onClick={onClick}
          disabled={isPaymentPending}
        >
          {isLoading ? (
            <Spin spinning={isLoading} />
          ) : (
            t(`${translationPrefix}.form.submitButton.label`) +
            ' ' +
            new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total / 100)
          )}
        </Button>
      </FormItem>
    </>
  );
};
