import { AppstoreAddOutlined, CreditCardOutlined, SolutionOutlined } from '@ant-design/icons';
import { Steps } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PublicPlanResDto } from '../../api/public/res/public-plan-res.dto';
import { Server } from '../../api/server-class';
import { IsErrorResDto } from '../../api/shared-types/error-res.dto';
import { LoginLinkComponent } from './components/login-link.component';
import { GeneralInformationComponent } from './components/patch-general-information.component';
import { PickPlanComponent } from './components/pick-plan.component';
import { ResendRegistrationLinkComponent } from './components/resend-registration-link.component';
import { StripeComponent } from './components/stripe.component';

export type SelectedPlan = PublicPlanResDto & { isSelected: boolean };

export const RegisterPage: React.FC = function RegisterPage() {
  const [current, setCurrent] = useState(0);
  const [plans, setPlans] = useState<SelectedPlan[] | null>(null);
  const location = useLocation();
  const [amountOfKats, setAmountOfKats] = useState<number>(0);

  const [emailConfirmationLoading, setEmailConfirmationLoading] = useState(false);
  const handleContinueRegistrationToken = useCallback(async () => {
    setEmailConfirmationLoading(true);
    try {
      const response = await Server.PublicConfirmEmail.get(location.search);
      if (!IsErrorResDto(response)) {
      }
    } finally {
      setEmailConfirmationLoading(false);
    }
  }, []);

  useEffect(() => {
    setAmountOfKats(0);
  }, []);
  useEffect(() => {
    if (location.search?.includes('continue-registration')) {
      handleContinueRegistrationToken();
    }
  }, [handleContinueRegistrationToken, location.search]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const [referral, setReferral] = useState('');

  const steps = [
    {
      title: 'Enter your information',
      icon: <SolutionOutlined />,
      status: 'finish',
      content: <GeneralInformationComponent next={next} />,
    },
    {
      title: 'Choose a License',
      icon: <AppstoreAddOutlined />,
      status: 'error',
      content: (
        <PickPlanComponent
          next={next}
          prev={prev}
          setPickedPlan={setPlans}
          setAmountOfKats={setAmountOfKats}
          katNumber={amountOfKats}
          setReferral={setReferral}
        />
      ),
    },
    {
      title: 'Checkout',
      icon: <CreditCardOutlined />,
      status: 'error',
      content: (
        <StripeComponent
          prev={prev}
          pickedPlan={plans?.find((x) => x.isSelected)}
          katDevicesAmount={amountOfKats}
          referral={referral}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title, icon: item.icon! }));
  return (
    <>
      <Steps current={current} items={items} className="all-steps my-3" />
      {steps[current].content}
      <LoginLinkComponent />
      <ResendRegistrationLinkComponent />
    </>
  );
};
