import { useLocation, useNavigate } from 'react-router';

export const BlackLogoImageComponent = function () {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className="text-center"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        if (location.pathname === '/') {
          window.location.reload();
        }
        navigate('/');
      }}
    >
      <img src="/images/logo.png" className="img-fluid mw-150 my-3" alt="Logo" />
    </div>
  );
};
