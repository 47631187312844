import { Select } from 'antd';
import { DefaultOptionType, FilterFunc } from 'rc-select/lib/Select';
import { useState } from 'react';

import './float-input.css';

interface Props {
  label: string;
  value?: any;
  placeholder?: string;
  required?: boolean;
  onChange?: (value: any, option?: any | any[]) => void;
  filterOption?: boolean | FilterFunc<DefaultOptionType>;
  onSearch?: (e: any) => void;
  options?: any;
  showSearch?: boolean;
  style?: any;
  allowClear?: boolean;
  optionFilterProp?: string;
  defaultActiveFirstOption?: boolean;
}

export const FloatSelect = function FloatSelect(props: Props) {
  const [focus, setFocus] = useState(false);
  let {
    label,
    value,
    placeholder,
    required,
    options,
    showSearch,
    optionFilterProp,
    style,
    allowClear,
    onChange,
    onSearch,
    defaultActiveFirstOption,
    filterOption,
  } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';

  const requiredMark = required ? (
    <span className="text-danger" style={{ color: 'red' }}>
      *
    </span>
  ) : null;
  return (
    <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      <Select
        className="float-select"
        size="large"
        options={options}
        onChange={onChange}
        value={value}
        showSearch={showSearch}
        optionFilterProp={optionFilterProp}
        style={{ ...style, fontSize: 14 }}
        allowClear={allowClear}
        onSearch={onSearch}
        defaultActiveFirstOption={defaultActiveFirstOption}
        filterOption={filterOption}
      />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};
