import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Row, Spin, Tooltip } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RuleObject } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { countries } from 'countries-list';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { PatchPublicUserReqDto } from '../../../api/public/req/patch-public-user-req.dto';
import { Server } from '../../../api/server-class';
import { IsErrorResDto } from '../../../api/shared-types/error-res.dto';
import { FloatInput } from '../../../components/input/float-input';
import { FloatSelect } from '../../../components/input/float-select';
import { GetStatesForCountry } from './states';
import { TaxIdComponent } from './tax-id.component';

const translationPrefix = 'registerPage';

export const GeneralInformationComponent: FC<{ next: () => void }> = function GeneralInformationComponent({ next }) {
  const [hasAcceptedTermsAndConditions, setHasAcceptedTermsAndConditions] = useState(false);
  const [wantsToEnterBillingAddress, setWantsToEnterBillingAddress] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const location = useLocation();
  const [form] = useForm<PatchPublicUserReqDto>();
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const onFinish = async (values: PatchPublicUserReqDto) => {
    try {
      setLoading(true);
      localStorage.setItem(
        GeneralInformationComponent.name,
        JSON.stringify({ ...values, hasAcceptedTermsAndConditions: false }),
      );
      const urlSearchParams = new URLSearchParams(location.search);
      const email = urlSearchParams.get('email');
      const token = urlSearchParams.get('continue-registration');
      if (!token || !email) {
        return;
      }

      if (isBusinessPurchase) {
        values.taxInformation = {
          businessName: values.taxInformation?.businessName ?? '',
          taxId: values.vatCountry + '---' + values.vatId,
        };
      }

      values.acceptedTermsAndConditionsOn = new Date().toISOString();
      const response = await Server.PublicUser.patch({ ...values, email, token });
      if (!IsErrorResDto(response)) {
        next();
      }
    } finally {
      setLoading(false);
    }
  };
  const statesForCountryCode = GetStatesForCountry(countryCode);

  const validateZipCode = (rule: RuleObject, value: any) => {
    const data = statesForCountryCode;
    if (data && data.states?.length) {
      const currentState = form.getFieldValue(['shippingAddress', 'state']);
      if (currentState) {
        const state = data.states.find((x: any) => x.code === currentState);
        if (state && state.zipRegex) {
          const isValid = new RegExp(state.zipRegex).test(value);
          if (!isValid) {
            return Promise.reject(new Error('Invalid ZIP code for provided state'));
          }
        }
      }
    }

    return Promise.resolve();
  };

  const [isLoadingFromLocalStorage, setIsLoadingFromLocalStorage] = useState(false);
  useEffect(() => {
    const fromLocalStorage = localStorage.getItem(GeneralInformationComponent.name);
    if (fromLocalStorage) {
      const obj: PatchPublicUserReqDto = JSON.parse(fromLocalStorage);
      form.setFieldsValue(obj);
      setHasAcceptedTermsAndConditions(obj.hasAcceptedTermsAndConditions);
      setWantsToEnterBillingAddress(Boolean(obj.billingAddress));
      setBusinessPurchase(Boolean(obj.isBusinessPurchase));
      setCountryCode(obj.shippingAddress?.country ?? '');
    }
    setIsLoadingFromLocalStorage(true);
  }, [form]);

  const countriesMemo = useMemo(() => {
    const countriesArray: {
      value: string;
      label: string;
      object: any;
    }[] = [];
    let countryKey: keyof typeof countries;
    for (countryKey in countries) {
      countriesArray.push({
        value: countryKey,
        label: countries[countryKey].name,
        object: countries[countryKey],
      });
    }
    return countriesArray;
  }, []);
  const [isBusinessPurchase, setBusinessPurchase] = useState(false);

  const email = new URLSearchParams(location.search).get('email');
  return (
    <>
      <h1 className="text-center text-uppercase fw-bold">Step 2: Purchase KAT System.</h1>
      <Tooltip style={{ color: 'var(--ant-primary-color)' }} title="Your email" className="mb-1">
        {email}
        <CheckCircleFilled style={{ color: 'green' }} className="ms-1" /> <i>email verified</i>
      </Tooltip>
      <div className="step-page">
        {isLoadingFromLocalStorage && (
          <Form onFinish={onFinish} form={form} className="my-2">
            <Row className="row-form-item" gutter={12}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: t(`${translationPrefix}.form.firstName.required`) as string,
                    },
                  ]}
                >
                  <FloatInput label={t(`${translationPrefix}.form.firstName.label`)} required />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: t(`${translationPrefix}.form.firstName.required`) as string }]}
                >
                  <FloatInput label={t(`${translationPrefix}.form.lastName.label`)} required />
                </Form.Item>
              </Col>
            </Row>
            <FormItem label={t(`${translationPrefix}.form.shippingAddress.checkbox.label`)}></FormItem>

            <FormItem
              name={['shippingAddress', 'country']}
              rules={[
                {
                  required: true,
                  message: t(`${translationPrefix}.form.address.country.required`) as string,
                },
              ]}
            >
              <FloatSelect
                options={countriesMemo}
                showSearch
                label={t(`${translationPrefix}.form.address.country.label`)}
                onChange={(x) => {
                  form.setFieldValue(['shippingAddress', 'country'], x);
                  form.setFieldValue(['shippingAddress', 'state'], '');
                  setCountryCode(x);
                }}
                filterOption={(input, option) => {
                  const regex = new RegExp(input, 'i');
                  return regex.test(option!.label as string);
                }}
                required
              />
            </FormItem>
            <FormItem
              name={['shippingAddress', 'address']}
              rules={[
                {
                  required: true,
                  message: t(`${translationPrefix}.form.address.streetAddress.required`) as string,
                },
              ]}
            >
              <FloatInput label={t(`${translationPrefix}.form.address.streetAddress.label`)} required />
            </FormItem>
            <Row className="row-form-item" gutter={12}>
              <Col xs={24} md={12}>
                <Form.Item
                  name={['shippingAddress', 'postalCode']}
                  rules={[
                    {
                      required: true,
                      message: t(`${translationPrefix}.form.address.postalCode.required`) as string,
                    },
                    {
                      validator: validateZipCode,
                    },
                  ]}
                >
                  <FloatInput label={t(`${translationPrefix}.form.address.postalCode.label`)} required />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <FormItem
                  name={['shippingAddress', 'city']}
                  rules={[
                    {
                      required: true,
                      message: t(`${translationPrefix}.form.address.city.required`) as string,
                    },
                  ]}
                >
                  <FloatInput label={t(`${translationPrefix}.form.address.city.label`)} required />
                </FormItem>
              </Col>
            </Row>

            {statesForCountryCode.states?.length && (
              <FormItem
                name={['shippingAddress', 'state']}
                rules={[
                  {
                    required: true,
                    message: 'State is required',
                  },
                ]}
              >
                {/* <FloatInput label={t(`${translationPrefix}.form.address.state.label`)} /> */}
                <FloatSelect
                  options={statesForCountryCode.states?.map((x: any) => {
                    return {
                      value: x.code,
                      label: x.name,
                      object: x,
                    };
                  })}
                  showSearch
                  label={t(`${translationPrefix}.form.address.state.label`)}
                  onChange={(x) => {
                    form.setFieldValue(['shippingAddress', 'state'], x);
                  }}
                  filterOption={(input, option) => {
                    const regex = new RegExp(input, 'i');
                    return regex.test(option!.label as string);
                  }}
                  required
                />
              </FormItem>
            )}

            <Checkbox
              checked={wantsToEnterBillingAddress}
              name="wantsToEnterBillingAddress"
              onChange={(event) => setWantsToEnterBillingAddress(event.target.checked)}
            >
              <span className="checkbox-label">{t(`${translationPrefix}.form.billingAddress.checkbox.label`)}</span>
            </Checkbox>
            {!wantsToEnterBillingAddress ? (
              <></>
            ) : (
              <>
                <FormItem
                  className="mt-2"
                  name={['billingAddress', 'country']}
                  rules={[
                    {
                      required: true,
                      message: t(`${translationPrefix}.form.address.country.required`) as string,
                    },
                  ]}
                >
                  <FloatSelect
                    options={countriesMemo}
                    showSearch
                    label={t(`${translationPrefix}.form.address.country.label`)}
                    filterOption={(input, option) => {
                      const regex = new RegExp(input, 'i');
                      return regex.test(option!.label as string);
                    }}
                    required
                  />
                </FormItem>
                <Row className="row-form-item" gutter={12}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name={['billingAddress', 'postalCode']}
                      rules={[
                        {
                          required: true,
                          message: t(`${translationPrefix}.form.address.postalCode.required`) as string,
                        },
                      ]}
                    >
                      <FloatInput label={t(`${translationPrefix}.form.address.postalCode.label`)} required />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <FormItem
                      name={['billingAddress', 'city']}
                      rules={[
                        {
                          required: true,
                          message: t(`${translationPrefix}.form.address.city.required`) as string,
                        },
                      ]}
                    >
                      <FloatInput label={t(`${translationPrefix}.form.address.city.label`)} required />
                    </FormItem>
                  </Col>
                </Row>
                <FormItem
                  name={['billingAddress', 'address']}
                  rules={[
                    {
                      required: true,
                      message: t(`${translationPrefix}.form.address.streetAddress.required`) as string,
                    },
                  ]}
                >
                  <FloatInput label={t(`${translationPrefix}.form.address.streetAddress.label`)} required />
                </FormItem>

                <FormItem name={['billingAddress', 'state']}>
                  <FloatInput label={t(`${translationPrefix}.form.address.state.label`)} />
                </FormItem>
              </>
            )}
            <div>
              <Checkbox
                checked={isBusinessPurchase}
                onChange={(event) => {
                  setBusinessPurchase(event.target.checked);
                }}
              >
                <span className="checkbox-label">
                  {t(`${translationPrefix}.form.isBusinessPurchase.checkbox.label`)}
                </span>
              </Checkbox>
            </div>
            {!isBusinessPurchase ? (
              <></>
            ) : (
              <>
                <TaxIdComponent form={form} />
                <FormItem
                  name={['taxInformation', 'businessName']}
                  rules={[
                    {
                      required: isBusinessPurchase,
                      message: t(`${translationPrefix}.form.taxInformation.businessName.required`) as string,
                    },
                  ]}
                >
                  <FloatInput
                    label={t(`${translationPrefix}.form.taxInformation.businessName.label`)}
                    required={isBusinessPurchase}
                  />
                </FormItem>
                {/* <FormItem
                  name={['taxInformation', 'taxId']}
                  rules={[
                    {
                      required: isBusinessPurchase,
                      message: t(`${translationPrefix}.form.taxInformation.taxId.required`) as string,
                    },
                  ]}
                >
                  <FloatInput
                    label={t(`${translationPrefix}.form.taxInformation.taxId.label`)}
                    required={isBusinessPurchase}
                  />
                </FormItem> */}
              </>
            )}
            <Row>
              <Col md={8}></Col>
              <Col md={16}>
                <FormItem
                  name={'hasAcceptedTermsAndConditions'}
                  className="my-3"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, __) {
                        if (hasAcceptedTermsAndConditions) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(t(`${translationPrefix}.form.hasAcceptedTermsAndConditions.required`) as string),
                        );
                      },
                    }),
                  ]}
                >
                  <Checkbox
                    checked={hasAcceptedTermsAndConditions}
                    onChange={(e: CheckboxChangeEvent) => {
                      form.setFieldValue('hasAcceptedTermsAndConditions', e.target.checked);
                      setHasAcceptedTermsAndConditions(e.target.checked);
                    }}
                  >
                    {' '}
                    <span className="checkbox-label  fs-4">
                      I accept the (
                      <u>
                        <a
                          href="https://panthertec.helpshift.com/hc/en/3-panthertec-help-center/faq/53-what-are-panthertec-s-terms-and-conditions-1678393345/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          TERMS & CONDITIONS
                        </a>
                      </u>
                      ) AND (
                      <u>
                        <a
                          href="https://panthertec.helpshift.com/hc/en/3-panthertec-help-center/faq/56-about-our-privacy-policy/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          PRIVACY POLICY
                        </a>
                      </u>
                      ).
                      <span style={{ color: 'red', fontWeight: 'normal', fontSize: '14px' }}>*</span>
                    </span>
                  </Checkbox>
                  <p>
                    {' '}
                    Terms & Conditions page says that you won't use the at KAT in any way that jeopardizes the health or
                    safety of yourself or anyone else (duh) , and that you won't try to reverse engineer or
                    commercialize any of the intellectual property, trade secrets etc from PantherTec,( also duh).
                    Privacy Policy page is also here for you to read and accept it. This says what we do and won't do
                    with your data.
                  </p>
                </FormItem>
              </Col>
            </Row>
          </Form>
        )}
      </div>
      <div style={{ marginTop: 24 }}>
        <Button
          type="primary"
          className="mb-2"
          onClick={async () => {
            await form.validateFields();
            form.submit();
          }}
        >
          {isLoading ? <Spin spinning={isLoading}></Spin> : t(`Next`)}
        </Button>
      </div>
    </>
  );
};
