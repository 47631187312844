import { Button, Form } from 'antd/es';
import { useState } from 'react';

import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { Server } from '../../api/server-class';
import { IsErrorResDto } from '../../api/shared-types/error-res.dto';
import { FloatInput } from '../../components/input/float-input';
import { EmailSendSuccessFullyComponent } from '../register/components/email-sent-succefully.component';
import { LoginLinkComponent } from '../register/components/login-link.component';

export const ResendRegistrationEmailPage = function ResendRegistrationEmailPage() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showAfterSubmitMessage, setShowAfterSubmitMessage] = useState(false);
  const [form] = Form.useForm();

  const onFormSubmit = async (value: any) => {
    setLoading(true);
    const response = await Server.PublicResendRegistrationEmail.post({
      to: value.email,
    });
    setLoading(false);

    if (!IsErrorResDto(response)) {
      setShowAfterSubmitMessage(true);
    }
  };
  return (
    <>
      <Spin spinning={loading}>
        <h1 className="text-center text-uppercase fw-bold">Resend verification email.</h1>
        <div hidden={!showAfterSubmitMessage}>
          <EmailSendSuccessFullyComponent email={form.getFieldValue('email')} />
        </div>

        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFormSubmit}
          hidden={showAfterSubmitMessage}
          form={form}
          className="my-2"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: `${t('resendRegistrationEmailPage.form.email.required')}`,
                type: 'email',
              },
            ]}
          >
            <FloatInput label={t('resendRegistrationEmailPage.form.email.label')} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>
              {t('resendRegistrationEmailPage.form.button.label')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
      <LoginLinkComponent />
    </>
  );
};
