import { Elements } from '@stripe/react-stripe-js';
import { Button, Col, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { InvoiceData } from '../../../api/public/res/public-stripe-setup-intent-res.dto';
import { Server } from '../../../api/server-class';
import { IsErrorResDto } from '../../../api/shared-types/error-res.dto';
import { GetOrCreateStripeAsync } from '../../../api/stripe/stripe.config';
import { SelectedPlan } from '../register.page';
import { CenteredSpinnerComponent } from './centered-spinner.component';
import { StripeCheckoutComponent } from './stripe-checkout.component';

const stripePromise = GetOrCreateStripeAsync();
export const StripeComponent: React.FC<{
  pickedPlan: SelectedPlan | undefined;
  katDevicesAmount: number | null;
  prev: () => void;
  referral: string;
}> = function StripeComponent({ pickedPlan, katDevicesAmount, prev, referral }) {
  const location = useLocation();
  const [clientSecret, setClientSecret] = useState('');
  const [invoiceData, setInvoiceData] = useState<null | InvoiceData>(null);
  const navigate = useNavigate();
  const [retryButton, showRetryButton] = useState(false);
  const load = useCallback(async () => {
    if (!pickedPlan) {
      return;
    }
    const searchparams = new URLSearchParams(location.search);
    const token = searchparams.get('continue-registration');
    const email = searchparams.get('email');
    if (!email || !token) {
      return;
    }
    const response = await Server.PublicStripePaymentIntent.post({
      katDevicesAmount: katDevicesAmount ?? 0,
      planId: pickedPlan.id,
      email,
      token,
      referredBy: referral,
    });

    if (!IsErrorResDto(response)) {
      setClientSecret(response.data.client_secret);
      setInvoiceData(response.data);
    } else {
      const err = response;
      if (err.statusCode === 40011) {
        navigate('/login');
      } else {
        showRetryButton(true);
      }
    }
  }, []);
  useEffect(() => {
    load();
  }, [load]);

  const reload = async () => {
    showRetryButton(false);
    setClientSecret('');
    await load();
  };

  return (
    <>
      {retryButton ? (
        <Row className="d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
          <Col>
            <Button type="primary" onClick={reload}>
              Retry
            </Button>
          </Col>
        </Row>
      ) : !clientSecret ? (
        <CenteredSpinnerComponent spinning={!Boolean(clientSecret)} />
      ) : (
        <>
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <StripeCheckoutComponent
              plan={pickedPlan!}
              katDevicesAmount={katDevicesAmount}
              refreshClientSecret={reload}
              invoiceData={invoiceData}
            />
          </Elements>
        </>
      )}

      <div style={{ marginTop: 24 }}>
        <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
          Previous
        </Button>
      </div>
    </>
  );
};
