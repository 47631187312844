import { SolutionOutlined } from '@ant-design/icons';
import { Button, Form, Spin, Steps } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PostPublicRegisterReqDto } from '../../api/public/req/post-public-register-req.dto';
import { Server } from '../../api/server-class';
import { IsErrorResDto } from '../../api/shared-types/error-res.dto';
import { FloatInput } from '../../components/input/float-input';
import { EmailSentContinueRegistrationComponent } from './components/email-sent-continiue-registration.component';
import { LoginLinkComponent } from './components/login-link.component';
import { ResendRegistrationLinkComponent } from './components/resend-registration-link.component';

export const PreRegisterPage = function PreRegisterPage() {
  const [form] = useForm<PostPublicRegisterReqDto>();
  const [isLoading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { t } = useTranslation();
  const onFinish = async (values: PostPublicRegisterReqDto) => {
    try {
      setLoading(true);
      const response = await Server.PublicRegister.post(values);
      if (!IsErrorResDto(response)) {
        setEmailSent(true);
      }
    } finally {
      setLoading(false);
    }
  };
  const translationPrefix = 'registerPage';
  return (
    <>
      {emailSent ? (
        <EmailSentContinueRegistrationComponent email={form.getFieldValue('email')} />
      ) : (
        <>
          <h1 className="text-center text-uppercase fw-bold">Step 1: Create an Account.</h1>
          <Steps>
            <Steps.Step icon={<SolutionOutlined />} title="Enter your information"></Steps.Step>
          </Steps>
          <Form onFinish={onFinish} form={form} className="my-2">
            <FormItem
              name="email"
              rules={[
                {
                  required: true,
                  message: t(`${translationPrefix}.form.email.required`) as string,
                  type: 'email',
                },
              ]}
            >
              <FloatInput type="email" label={t(`${translationPrefix}.form.email.label`)} required />
            </FormItem>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: t(`${translationPrefix}.form.password.required`) as string },
                {
                  min: 8,
                  message: 'Password must be 8 or more characters',
                },
              ]}
            >
              <FloatInput label={t(`${translationPrefix}.form.password.label`)} required type="password" />
            </Form.Item>

            <FormItem
              name="confirmPassword"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: `${t(`${translationPrefix}.form.repeatedPassword.required`)}`,
                  type: 'string',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(`${t(`${translationPrefix}.form.repeatedPassword.mustMatch`)}`));
                  },
                }),
              ]}
            >
              <FloatInput type="password" required label={t(`${translationPrefix}.form.repeatedPassword.label`)} />
            </FormItem>
            <Button type="primary" htmlType="submit" block disabled={isLoading}>
              {isLoading ? <Spin spinning={isLoading}></Spin> : t(`${translationPrefix}.preRegister.button.label`)}
            </Button>
          </Form>
        </>
      )}
      <LoginLinkComponent />
      <ResendRegistrationLinkComponent />
    </>
  );
};
