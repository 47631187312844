import { Avatar, Button, Checkbox, Col, Form, InputNumber, Row, Spin } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useForm } from 'antd/lib/form/Form';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PublicOneTimeProductResDto } from '../../../api/public/res/public-one-time-product-res.dto';
import { Server } from '../../../api/server-class';
import { IsErrorResDto } from '../../../api/shared-types/error-res.dto';
import { SelectedPlan } from '../register.page';
import { PlanComponent } from './plan.component';
import { ReferredByComponent } from './reffered-by.component';
const translationPrefix = 'registerPage';
const contentStyle: React.CSSProperties = {
  lineHeight: '260px',
  textAlign: 'center',
  marginTop: 16,
};
export const PickPlanComponent: FC<{
  next: () => void;
  prev: () => void;
  setAmountOfKats: (number: number) => void;
  katNumber: number | null;
  setPickedPlan: (plan: SelectedPlan[] | null) => void;
  setReferral: (value: string) => void;
}> = function PickPlanComponent({ next, prev, setPickedPlan, setAmountOfKats, katNumber, setReferral }) {
  const [plans, setPlans] = useState<SelectedPlan[] | null>(null);
  const [wantsAdditionalDevices, setWantsAdditionalDevices] = useState(false);
  const [oneTimeDevices, setOneTimeDevices] = useState<PublicOneTimeProductResDto[] | null>(null);
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const [form] = useForm<{ pickedPlan: SelectedPlan; numberOfKatDevices: number; referredBy: string }>();
  useEffect(() => {
    if (wantsAdditionalDevices) {
      setAmountOfKats(1);
    } else {
      setAmountOfKats(0);
    }
  }, [wantsAdditionalDevices, setAmountOfKats]);
  const load = useCallback(async () => {
    const planResponse = await Server.PublicPlan.get();
    if (!IsErrorResDto(planResponse)) {
      const plans = planResponse.data.map((x) => {
        return {
          ...x,
          isSelected: false,
        };
      });

      setPlans(plans);
    }
    const oneTimeDeviceResponse = await Server.PublicOneTimeProduct.get();
    if (!IsErrorResDto(oneTimeDeviceResponse)) {
      setOneTimeDevices(oneTimeDeviceResponse.data);
    }
  }, []);
  useEffect(() => {
    load();
  }, [load]);

  const onFinish = async (values: { pickedPlan: SelectedPlan; numberOfKatDevices: number; referredBy: string }) => {
    try {
      setLoading(true);
      localStorage.setItem(PickPlanComponent.name, JSON.stringify(values));
      setReferral(values.referredBy);
      next();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className="text-center text-uppercase fw-bold">Step 2: Purchase KAT System.</h1>
      <h2 className="text-center text-uppercase fw-bold">Choose your license.</h2>
      <div style={contentStyle} className="step-page">
        {
          <Form form={form} onFinish={onFinish} className="my-2">
            <FormItem name={['pickedPlan']} rules={[{ required: true, message: 'You must select a license ' }]}>
              <Row className="mx-auto my-2" gutter={24}>
                {plans?.map((plan) => (
                  <Col md={12} key={plan.id}>
                    <PlanComponent
                      plan={plan}
                      onClick={async (e) => {
                        const mappedPlans = plans.map((x) => ({
                          ...x,
                          isSelected: x.id === e.id,
                        }));
                        setPlans([...mappedPlans]);
                        form.setFieldValue(
                          'pickedPlan',
                          mappedPlans.find((x) => x.isSelected),
                        );
                        setPickedPlan([...mappedPlans]);
                        form.validateFields(['pickedPlan']);
                      }}
                    />
                  </Col>
                ))}
              </Row>
            </FormItem>
            <FormItem
              name="referredBy"
              rules={[
                {
                  required: true,
                  message: t(`${translationPrefix}.form.referredBy.required`) as string,
                  type: 'string',
                },
              ]}
            >
              <ReferredByComponent />
            </FormItem>

            <Checkbox
              checked={wantsAdditionalDevices}
              onChange={(e) => setWantsAdditionalDevices(e.target.checked)}
              className="mb-2"
            >
              <div>{t(`${translationPrefix}.form.wantsAdditionalDevices.checkbox.label`)}</div>
            </Checkbox>
            {!wantsAdditionalDevices ? (
              <></>
            ) : (
              <>
                <div className="">{t(`${translationPrefix}.form.wantsAdditionalDevices.checkbox.notePartOne`)}</div>
                <div className="mb-2">
                  <div className="">
                    <b> {t(`${translationPrefix}.form.wantsAdditionalDevices.checkbox.notePartTwo`)}</b>
                  </div>
                  <div className="">{t(`${translationPrefix}.form.wantsAdditionalDevices.checkbox.notePartThree`)}</div>
                </div>

                <FormItem
                  name={['additionalDevices']}
                  colon={false}
                  label={<span className="mt-2">Number of devices</span>}
                >
                  <div>
                    <InputNumber
                      title="Number of additional kat devices"
                      min={1}
                      max={5}
                      defaultValue={1}
                      bordered={false}
                      onChange={(e) => {
                        form.setFieldValue('numberOfKatDevices', e);
                        setAmountOfKats(e ?? 0);
                      }}
                      className="me-2"
                    />
                    <Avatar src={oneTimeDevices![0].base64Image} size={64} />
                    <span className="fw-bold ms-2">
                      {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                        (oneTimeDevices![0].amountInCents * (katNumber || 1)) / 100,
                      )}
                      {' + VAT / Sales Tax'}
                    </span>
                  </div>
                </FormItem>
              </>
            )}
          </Form>
        }
      </div>
      <div style={{ marginTop: 24 }}>
        <Button
          type="primary"
          className="mb-2"
          onClick={async () => {
            await form.validateFields();
            form.submit();
          }}
        >
          {isLoading ? <Spin spinning={isLoading}></Spin> : t(`Next`)}
        </Button>

        <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
          Previous
        </Button>
      </div>
    </>
  );
};
