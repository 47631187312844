export const LookingForTheKatAppComponent = function LookingForTheKatAppComponent() {
  return (
    <div className="container">
      <i>
        Looking for KAT App? Links to download are in the Welcome to Panthertec email. Check your spam and junk folders.
        *KAT App will be available on the App Store and Google PlayStore later in 2023
      </i>
    </div>
  );
};
