import { Result } from 'antd';

export function LogOutPage() {
  return (
    <>
      <h1 className="text-center text-uppercase fw-bold">Logout</h1>
      <Result status="success" title="" subTitle="Successfully logged out"></Result>
    </>
  );
}
