export const API_URL: string = process.env.REACT_APP_API_URL || throwFn('process.env.REACT_APP_API_URL NOT DEFINED');
export const STRIPE_API_KEY: string =
  process.env.REACT_APP_STRIPE_API_KEY || throwFn('process.env.REACT_APP_STRIPE_API_KEY NOT DEFINED');
export const REACT_APP_POST_PAYMENT_URL: string =
  process.env.REACT_APP_POST_PAYMENT_URL || throwFn('process.env.REACT_APP_POST_PAYMENT_URL NOT DEFINED');

export const REACT_APP_CALENDLY_URL: string =
  process.env.REACT_APP_CALENDLY_URL || throwFn('process.env.REACT_APP_CALENDLY_URL NOT DEFINED');
function throwFn(message: string): never {
  throw message;
}
