import { FormInstance } from 'antd/es';
import FormItem from 'antd/es/form/FormItem';
import { RuleObject } from 'antd/lib/form';
import { countries } from 'countries-list';
import { useMemo, useState } from 'react';
import { FloatInput } from '../../../components/input/float-input';
import { FloatSelect } from '../../../components/input/float-select';

type Props = {
  form: FormInstance<any>;
};

const taxJson = [
  {
    type: 'ae_trn',
    country: 'AE',
    format: '[0-9]{15}',
    placeholder: '123456789012345',
    external_type: 'ae_trn',
    verifiable: false,
    description: 'United Arab Emirates TRN',
  },
  {
    type: 'al_tin',
    country: 'AL',
    format: '[A-Z][0-9]{8}[A-Z]',
    placeholder: 'J12345678N',
    verifiable: false,
    external_type: 'unknown',
    description: 'Albania Tax Identification Number',
  },
  {
    type: 'at_vat',
    country: 'AT',
    format: 'ATU[0-9]{8}',
    placeholder: 'ATU12345678',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'au_abn',
    country: 'AU',
    format: '[0-9]{11}',
    placeholder: '12345678912',
    external_type: 'au_abn',
    verifiable: true,
    description: 'Australian Business Number (AU ABN)',
  },
  {
    type: 'au_arn',
    country: 'AU',
    format: '[0-9]{12}',
    placeholder: '123456789123',
    external_type: 'au_arn',
    verifiable: false,
    description: 'Australian Taxation Office Reference Number',
  },
  {
    type: 'be_vat',
    country: 'BE',
    format: 'BE(0|1)[0-9]{9}',
    placeholder: 'BE0123456789',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'bg_uic',
    country: 'BG',
    format: '[0-9]{9}',
    placeholder: '123456789',
    verifiable: false,
    external_type: 'bg_uic',
    description: 'Bulgaria Unified Identification Code',
  },
  {
    type: 'bg_vat',
    country: 'BG',
    format: 'BG[0-9]{9,10}',
    placeholder: 'BG0123456789',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'br_cnpj',
    country: 'BR',
    format: '([0-9]{2})\\\\.?([0-9]{3})\\\\.?([0-9]{3})[\\\\/\\\\\\\\]?([0-9]{4})\\\\-?([0-9]{2})',
    placeholder: '01.234.456/5432-10',
    external_type: 'br_cnpj',
    description: 'Brazilian CNPJ number',
    verifiable: false,
    replacement_string: '\\\\1.\\\\2.\\\\3/\\\\4-\\\\5',
  },
  {
    type: 'br_cpf',
    country: 'BR',
    format: '([0-9]{3})\\\\.?([0-9]{3})\\\\.?([0-9]{3})\\\\-?([0-9]{2})',
    placeholder: '123.456.789-87',
    external_type: 'br_cpf',
    description: 'Brazilian CPF number',
    verifiable: false,
    replacement_string: '\\\\1.\\\\2.\\\\3-\\\\4',
  },
  {
    type: 'ca_bn',
    country: 'CA',
    format: '[0-9]{9}',
    placeholder: '123456789',
    external_type: 'ca_bn',
    description: 'Canadian BN',
    verifiable: false,
  },
  {
    type: 'ca_gst_hst',
    country: 'CA',
    format: '([0-9]{9}) ?RT ?([0-9]{4})',
    placeholder: '123456789RT0002',
    external_type: 'ca_gst_hst',
    verifiable: false,
    replacement_string: '\\\\1RT\\\\2',
    description: 'Canadian GST/HST number',
  },
  {
    type: 'ca_pst_bc',
    country: 'CA',
    format: 'PST-?([0-9]{4})-?([0-9]{4})',
    placeholder: 'PST-1234-5678',
    external_type: 'ca_pst_bc',
    verifiable: false,
    replacement_string: 'PST-\\\\1-\\\\2',
    description: 'Canadian PST number (British Columbia)',
  },
  {
    type: 'ca_pst_mb',
    country: 'CA',
    format: '([0-9]{6})-?([0-9])',
    placeholder: '123456-7',
    external_type: 'ca_pst_mb',
    verifiable: false,
    replacement_string: '\\\\1-\\\\2',
    description: 'Canadian PST number (Manitoba)',
  },
  {
    type: 'ca_pst_sk',
    country: 'CA',
    format: '([0-9]{7})',
    placeholder: '1234567',
    external_type: 'ca_pst_sk',
    verifiable: false,
    description: 'Canadian PST number (Saskatchewan)',
  },
  {
    type: 'ca_qst',
    country: 'CA',
    format: '([0-9]{10}TQ[0-9]{4}|[0-9]{9}[A-Z]{2}[0-9]{4}|[0-9]{9,10}|NR[0-9]{8})',
    placeholder: '1234567890TQ1234',
    external_type: 'ca_qst',
    description: 'Canadian QST number (Québec)',
    verifiable: false,
  },
  {
    type: 'ch_vat',
    country: 'CH',
    placeholder: 'CHE-123.456.789 MWST',
    format:
      '([Cc][Hh][Ee])[\\\\. \\\\-]?(\\\\d{3})[\\\\. \\\\-]?(\\\\d{3})[\\\\. \\\\-]?(\\\\d{3}) ?([Mm][Ww][Ss][Tt]|[Tt][Vv][Aa]|[Ii][Vv][Aa])',
    external_type: 'ch_vat',
    description: 'Switzerland VAT number',
    verifiable: false,
    replacement_string: '\\\\1-\\\\2.\\\\3.\\\\4 \\\\5',
  },
  {
    type: 'cl_tin',
    country: 'CL',
    format: '([0-9]{2})\\\\.?([0-9]{3})\\\\.?([0-9]{3})-?([K0-9])',
    placeholder: '12.345.678-K',
    external_type: 'cl_tin',
    description: 'Chilean TIN',
    verifiable: false,
    replacement_string: '\\\\1.\\\\2.\\\\3-\\\\4',
  },
  {
    type: 'cy_vat',
    country: 'CY',
    format: 'CY[0-9]{8}[A-Z]',
    placeholder: 'CY12345678Z',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'cz_vat',
    country: 'CZ',
    format: 'CZ[0-9]{8,10}',
    placeholder: 'CZ1234567890',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'de_vat',
    country: 'DE',
    format: 'DE[0-9]{9}',
    placeholder: 'DE123456789',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'dk_vat',
    country: 'DK',
    format: 'DK[0-9]{8}',
    placeholder: 'DK12345678',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'ee_vat',
    country: 'EE',
    format: 'EE[0-9]{9}',
    placeholder: 'EE123456789',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'eg_tin',
    country: 'EG',
    format: '[0-9]{9}',
    placeholder: '123456789',
    verifiable: false,
    external_type: 'eg_tin',
    description: 'Egyptian Tax Identification Number',
  },
  {
    type: 'es_cif',
    country: 'ES',
    format: '[0-9A-Z][0-9]{7}[0-9A-Z]',
    placeholder: 'A12345678',
    external_type: 'es_cif',
    description: 'Spanish NIF number (previously Spanish CIF number)',
    verifiable: false,
  },
  {
    type: 'es_vat',
    country: 'ES',
    format: 'ES([A-Z][0-9]{8}|[0-9]{8}[A-Z]|[A-Z][0-9]{7}[A-Z])',
    placeholder: 'ESA1234567Z',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'eu_oss_vat',
    country: 'EU',
    format: 'EU[0-9]{9}',
    placeholder: 'EU123456789',
    external_type: 'eu_oss_vat',
    verifiable: false,
    description: 'European One Stop Shop VAT number for non-Union scheme',
  },
  {
    type: 'fi_vat',
    country: 'FI',
    format: 'FI[0-9]{8}',
    placeholder: 'FI12345678',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'fr_vat',
    country: 'FR',
    format: 'FR[A-Z0-9]{2}[0-9]{9}',
    placeholder: 'FRAB123456789',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'gb_vat',
    country: 'GB',
    format: 'GB([0-9]{9}|[0-9]{12}|(HA|GD)[0-9]{3})',
    placeholder: 'GB123456789',
    external_type: 'gb_vat',
    verifiable: true,
    description: 'United Kingdom VAT number',
  },
  {
    type: 'ge_vat',
    country: 'GE',
    format: '([0-9]{9}|[0-9]{11})',
    placeholder: '123456789',
    verifiable: false,
    external_type: 'ge_vat',
    description: 'Georgian VAT',
  },
  {
    type: 'gr_vat',
    country: 'GR',
    format: 'EL[0-9]{9}',
    placeholder: 'EL123456789',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'hk_br',
    country: 'HK',
    format: '[0-9]{8}',
    placeholder: '12345678',
    external_type: 'hk_br',
    description: 'Hong Kong BR number',
    verifiable: false,
  },
  {
    type: 'hr_vat',
    country: 'HR',
    format: 'HR[0-9]{11}',
    placeholder: 'HR12345678912',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'hu_tin',
    country: 'HU',
    format: '([0-9]{8})-?([0-9]{1})-?([0-9]{2})',
    placeholder: '12345678-1-23',
    verifiable: false,
    external_type: 'hu_tin',
    description: 'Hungary tax number (adószám)',
    replacement_string: '\\\\1-\\\\2-\\\\3',
  },
  {
    type: 'hu_vat',
    country: 'HU',
    format: '(HU)?[0-9]{8}((-)?[0-9]{1}(-)?[0-9]{2})?',
    hostedUIFormat: 'HU[0-9]{8}((-)?[0-9]{1}(-)?[0-9]{2})?',
    placeholder: 'HU12345678912',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'id_npwp',
    country: 'ID',
    format: '([0-9]{2})\\\\.?([0-9]{3})\\\\.?([0-9]{3})\\\\.?([0-9])-?([0-9]{3})\\\\.?([0-9]{3})',
    placeholder: '12.345.678.9-012.345',
    verifiable: false,
    external_type: 'id_npwp',
    description: 'Indonesian NPWP number',
    replacement_string: '\\\\1.\\\\2.\\\\3.\\\\4-\\\\5.\\\\6',
  },
  {
    type: 'ie_vat',
    country: 'IE',
    format: 'IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])',
    placeholder: 'IE1234567AB',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'il_vat',
    country: 'IL',
    format: '[0-9]{9}',
    placeholder: '000012345',
    verifiable: false,
    external_type: 'il_vat',
    description: 'Israel VAT',
  },
  {
    type: 'in_gst',
    country: 'IN',
    format: '^(([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][1-9A-Z]{2}[0-9A-Z])|(9921USA29044OSI))$',
    placeholder: '12ABCDE3456FGZH',
    external_type: 'in_gst',
    description: 'Indian GST number',
    verifiable: false,
  },
  {
    type: 'is_vat',
    country: 'IS',
    format: '[0-9]{6}',
    placeholder: '123456',
    verifiable: false,
    external_type: 'is_vat',
    description: 'Icelandic VAT',
  },
  {
    type: 'it_vat',
    country: 'IT',
    format: 'IT[0-9]{11}',
    placeholder: 'IT12345678912',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'jp_cn',
    country: 'JP',
    format: '[0-9]{13}',
    placeholder: '1234567891234',
    external_type: 'jp_cn',
    description: 'Japanese Corporate Number (*Hōjin Bangō*)',
    verifiable: false,
  },
  {
    type: 'jp_rn',
    country: 'JP',
    format: '[0-9]{5}',
    placeholder: '12345',
    external_type: 'jp_rn',
    description:
      "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
    verifiable: false,
  },
  {
    type: 'jp_trn',
    country: 'JP',
    format: 'T[0-9]{13}',
    placeholder: 'T1234567891234',
    external_type: 'jp_trn',
    description: 'Japanese Tax Registration Number (*Tōroku Bangō*)',
    verifiable: false,
  },
  {
    type: 'ke_pin',
    country: 'KE',
    format: 'P[0-9]{9}[A-Z]',
    placeholder: 'P000111111A',
    verifiable: false,
    external_type: 'ke_pin',
    description: 'Kenya Revenue Authority Personal Identification Number',
  },
  {
    type: 'kr_brn',
    country: 'KR',
    format: '([0-9]{3})-?([0-9]{2})-?([0-9]{5})',
    placeholder: '123-45-67890',
    external_type: 'kr_brn',
    description: 'Korean BRN',
    verifiable: false,
    replacement_string: '\\\\1-\\\\2-\\\\3',
  },
  {
    type: 'li_uid',
    country: 'LI',
    format: 'CHE[0-9]{9}',
    placeholder: 'CHE123456789',
    external_type: 'li_uid',
    description: 'Liechtensteinian UID number',
    verifiable: false,
  },
  {
    type: 'lt_vat',
    country: 'LT',
    format: 'LT([0-9]{9}|[0-9]{12})',
    placeholder: 'LT123456789123',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'lu_vat',
    country: 'LU',
    format: 'LU[0-9]{8}',
    placeholder: 'LU12345678',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'lv_vat',
    country: 'LV',
    format: 'LV[0-9]{11}',
    placeholder: 'LV12345678912',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'mt_vat',
    country: 'MT',
    format: 'MT[0-9]{8}',
    placeholder: 'MT12345678',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'mx_rfc',
    country: 'MX',
    format: '([A-Za-zÑñ&]{3,4})-?([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])-?([A-Za-z0-9])-?([A-Za-z0-9][0-9A])',
    placeholder: 'ABC010203AB9',
    external_type: 'mx_rfc',
    description: 'Mexican RFC number',
    verifiable: false,
    replacement_string: '\\\\1\\\\2\\\\3\\\\4\\\\5\\\\6',
  },
  {
    type: 'my_frp',
    country: 'MY',
    format: '[0-9]{8}',
    placeholder: '12345678',
    verifiable: false,
    external_type: 'my_frp',
    description: 'Malaysian FRP number',
  },
  {
    type: 'my_itn',
    country: 'MY',
    format: '([A-Z]{1,2}) ?([0-9]{10})',
    placeholder: 'C 1234567890',
    external_type: 'my_itn',
    description: 'Malaysian ITN',
    verifiable: false,
    replacement_string: '\\\\1 \\\\2',
  },
  {
    type: 'my_sst',
    country: 'MY',
    format: '(([A-Z][0-9]{2})-?([0-9]{4})-?([0-9]{8})|[0-9]{8})',
    placeholder: 'A12-3456-78912345',
    external_type: 'my_sst',
    description: 'Malaysian SST number',
    verifiable: false,
    replacement_string: '\\\\2-\\\\3-\\\\4',
    only_replace_if_group_captured: 2,
  },
  {
    type: 'nl_vat',
    country: 'NL',
    format: 'NL[0-9]{9}B[0-9]{2}',
    placeholder: 'NL123456789B12',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'no_vat',
    country: 'NO',
    format: '[0-9]{9}MVA',
    placeholder: '123456789MVA',
    external_type: 'no_vat',
    description: 'Norwegian VAT number',
    verifiable: false,
  },
  {
    type: 'nz_gst',
    country: 'NZ',
    format: '[0-9]{8,9}',
    placeholder: '123456789',
    external_type: 'nz_gst',
    description: 'New Zealand GST number',
    verifiable: false,
  },
  {
    type: 'ph_tin',
    country: 'PH',
    format: '[0-9]{12}',
    placeholder: '123456789012',
    verifiable: false,
    external_type: 'ph_tin',
    description: 'Philippines Tax Identification Number',
  },
  {
    type: 'pl_vat',
    country: 'PL',
    format: 'PL[0-9]{10}',
    placeholder: 'PL1234567890',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'pt_vat',
    country: 'PT',
    format: 'PT[0-9]{9}',
    placeholder: 'PT123456789',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'ro_vat',
    country: 'RO',
    format: 'RO[0-9]{2,10}',
    placeholder: 'RO1234567891',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'ru_inn',
    country: 'RU',
    format: '([0-9]{10}|[0-9]{12})',
    placeholder: '1234567891',
    external_type: 'ru_inn',
    description: 'Russian INN',
    verifiable: false,
  },
  {
    type: 'ru_kpp',
    country: 'RU',
    format: '[0-9]{9}',
    placeholder: '123456789',
    external_type: 'ru_kpp',
    description: 'Russian KPP',
    verifiable: false,
  },
  {
    type: 'sa_vat',
    country: 'SA',
    format: '[0-9]{15}',
    placeholder: '123456789012345',
    external_type: 'sa_vat',
    description: 'Saudi Arabia VAT',
    verifiable: false,
  },
  {
    type: 'se_vat',
    country: 'SE',
    format: 'SE[0-9]{12}',
    placeholder: 'SE123456789123',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'sg_gst',
    country: 'SG',
    format:
      '([0-9]{8}[A-Z]|[0-9]{4}[0-9]{5}[A-Z]|F(000|   )[0-9]{5}[A-Z]|[ST][0-9]{2}[A-Z]{2}[0-9]{4}[A-Z]|M[0-9A-Z][0-9]{7}[0-9A-Z]|F[0-9]{8}[A-Z])',
    placeholder: 'M12345678X',
    external_type: 'sg_gst',
    description: 'Singaporean GST',
    verifiable: false,
  },
  {
    type: 'sg_uen',
    country: 'SG',
    format: '([0-9]{8}[A-Z]|[0-9]{4}[0-9]{5}[A-Z]|F(000|   )[0-9]{5}[A-Z]|[ST][0-9]{2}[A-Z]{2}[0-9]{4}[A-Z])',
    placeholder: '123456789F',
    external_type: 'sg_uen',
    description: 'Singaporean UEN',
    verifiable: false,
  },
  {
    type: 'si_tin',
    country: 'SI',
    format: '[1-9]{1}[0-9]{7}',
    placeholder: '12345678',
    verifiable: false,
    external_type: 'si_tin',
    description: 'Slovenia tax number (davčna številka)',
  },
  {
    type: 'si_vat',
    country: 'SI',
    format: 'SI[0-9]{8}',
    placeholder: 'SI12345678',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'sk_vat',
    country: 'SK',
    format: 'SK[0-9]{10}',
    placeholder: 'SK1234567891',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'European VAT number',
  },
  {
    type: 'th_vat',
    country: 'TH',
    format: '[0-9]{13}',
    placeholder: '1234567891234',
    external_type: 'th_vat',
    description: 'Thai VAT',
    verifiable: false,
  },
  {
    type: 'tr_tin',
    country: 'TR',
    format: '[0-9]{10}',
    placeholder: '0123456789',
    verifiable: false,
    external_type: 'tr_tin',
    description: 'Turkish Tax Identification Number',
  },
  {
    type: 'tw_vat',
    country: 'TW',
    format: '[0-9]{8}',
    placeholder: '12345678',
    external_type: 'tw_vat',
    description: 'Taiwanese VAT',
    verifiable: false,
  },
  {
    type: 'ua_vat',
    country: 'UA',
    format: '([0-9]{8,10}|[0-9]{12})',
    placeholder: '123456789',
    verifiable: false,
    external_type: 'ua_vat',
    description: 'Ukrainian VAT',
  },
  {
    type: 'us_ein',
    country: 'US',
    format: '([0-9]{2})-?([0-9]{7})',
    placeholder: '12-3456789',
    external_type: 'us_ein',
    description: 'United States EIN',
    verifiable: false,
    replacement_string: '\\\\1-\\\\2',
  },
  {
    type: 'xi_vat',
    country: 'GB',
    format: '(XI)([0-9]{9}|[0-9]{12}|(HA|GD)[0-9]{3})',
    placeholder: 'XI123456789',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: true,
    description: 'Northern Ireland VAT number',
  },
  {
    type: 'za_vat',
    country: 'ZA',
    format: '4[0-9]{9}',
    placeholder: '4123456789',
    external_type: 'za_vat',
    description: 'South African VAT number',
    verifiable: false,
  },
];

export const TaxIdComponent = (props: Props) => {
  const countriesMemo = useMemo(() => {
    const countriesArray: {
      value: string;
      label: string;
      object: any;
    }[] = [];
    let countryKey: keyof typeof countries;
    for (countryKey in countries) {
      const taxes = taxJson.filter((x) => x.country === countryKey);
      taxes.forEach((taxData) => {
        const country = countries[countryKey];
        const name = country.name;
        const emoji = country.emoji;
        countriesArray.push({
          value: taxData.type,
          object: taxData,
          label: `${emoji} ${name} ${taxData.description}`,
        });
      });
    }
    return countriesArray;
  }, []);

  const [placeHolderState, setPlaceholderState] = useState('');

  const validateTax = (rule: RuleObject, value: any) => {
    const vatRuleCountry = props.form.getFieldValue('vatCountryType');
    const taxValidation = taxJson.find((x) => x.type === vatRuleCountry);
    let isValid = true;
    if (taxValidation) {
      const regex = new RegExp('^' + taxValidation.format + '$');
      isValid = regex.test(value);
    }
    if (isValid) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Tax id is not in a valid format for your selected Tax ID type'));
  };

  return (
    <>
      <FormItem hidden name="vatCountryType" />
      <FormItem hidden name="vatCountry" />
      <FormItem
        className="mt-2"
        name="vatCountryDisplay"
        rules={[
          {
            required: true,
            message: 'You must pick a country',
          },
        ]}
      >
        <FloatSelect
          allowClear
          options={countriesMemo}
          showSearch
          label="Tax ID type"
          // value={selectedTax}
          filterOption={(input, option) => {
            const regex = new RegExp(input, 'i');
            return regex.test(option!.label as string);
          }}
          onChange={(value, allValues) => {
            if (!value) {
              return;
            }

            // props.form.submit();
            props.form.setFields([
              {
                name: 'vatCountryDisplay',
                value: allValues.label,
              },
            ]);
            props.form.setFields([
              {
                name: 'vatCountryType',
                value: allValues.object.type,
              },
            ]);

            props.form.setFields([
              {
                name: 'vatCountry',
                value: allValues.object.external_type,
              },
            ]);

            setPlaceholderState(taxJson?.find((x) => x.type === value)?.placeholder ?? '');
          }}
          required
        />
      </FormItem>

      <FormItem
        name="vatId"
        rules={[
          {
            required: true,
            message: 'Tax ID is required',
          },
          {
            validator: validateTax,
          },
        ]}
      >
        <FloatInput placeholder={placeHolderState} label="Tax Id number" required />
      </FormItem>
    </>
  );
};
