import axios from 'axios';
import { API_URL } from '../constants';
import { HandleAxiosErrorResDto, type ErrorResDto } from '../shared-types/error-res.dto';
import { GetPublicOneTimeProductResDto } from './res/get-public-one-time-product-res.dto';

export class PublicOneTimeProductServer {
  private readonly API_PATH: string = `${API_URL}/public/one-time-product`;

  async get(): Promise<GetPublicOneTimeProductResDto | ErrorResDto> {
    try {
      const response = await axios.get<GetPublicOneTimeProductResDto>(this.API_PATH);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
