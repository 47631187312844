import axios from 'axios';
import type { Credentials } from '../types/types';
import { API_URL } from './constants';

export const AuthTokenSymbol = 'authToken';

export class AuthServer {
  private readonly AUTH_API_PATH: string = `${API_URL}/auth`;

  private readonly storeAuthToken = (authToken?: string): void => {
    try {
      if (authToken != null) {
        window.localStorage.setItem(AuthTokenSymbol, authToken);
      } else {
        window.localStorage.removeItem(AuthTokenSymbol);
      }
    } catch {}
  };

  async login({ email, password }: Credentials): Promise<boolean> {
    try {
      const data = {
        email,
        password,
      };
      const response = await axios.post(this.AUTH_API_PATH, data);
      this.storeAuthToken(response.data.data.jwt);
      return true;
    } catch (err) {
      return false;
    }
  }

  async authenticate() {
    return await axios.get(this.AUTH_API_PATH);
  }

  async confirmRegister(token: string, email: string): Promise<boolean> {
    const params = new URLSearchParams();
    params.append('token', token);
    params.append('email', email);
    try {
      await axios.get(`${this.AUTH_API_PATH}/activate-account`, { params });
      return true;
    } catch (err) {
      return false;
    }
  }

  logout(): void {
    this.storeAuthToken(undefined);
  }
}
