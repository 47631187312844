import { Link } from 'react-router-dom';

export const LoginLinkComponent = function LoginLinkComponent() {
  return (
    <div className="container">
      Already have an account?{' '}
      <Link to="/login" className="fw-bold">
        Go to Login Portal.
      </Link>
    </div>
  );
};
