import axios from 'axios';
import { API_URL } from '../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../shared-types/error-res.dto';
import { PostPublicTokenIssuerReqDto } from './req/post-public-token-issuer-req.dto';
import { PostPublicTokenIssuerResDto } from './res/post-public-token-issuer-res.dto';

export class PublicTokenIssuerServer {
  private readonly API_PATH: string = `${API_URL}/public/token/issuer`;

  async post(data: PostPublicTokenIssuerReqDto): Promise<PostPublicTokenIssuerResDto | ErrorResDto> {
    try {
      const response = await axios.post<PostPublicTokenIssuerResDto>(this.API_PATH, data);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
