import ReactDOM from 'react-dom/client';

import { ConfigProvider } from 'antd';
import React from 'react';
import { SetupAxiosErrorInterceptor } from './api/axios-error.interceptor';
import { GetOrCreateStripeAsync } from './api/stripe/stripe.config';
import { App } from './App';
import { GetAndInitI18N } from './i18n/i18n.config';
import './index.css';

GetAndInitI18N();
SetupAxiosErrorInterceptor();
GetOrCreateStripeAsync();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ConfigProvider>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
