import { Result } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const EmailSendSuccessFullyComponent: FC<{ email: string }> = function EmailSendSuccessFullyComponent({
  email,
}) {
  const { t } = useTranslation();

  return (
    <Result
      status="success"
      title="Email sent"
      subTitle={
        <a className="centered" href={`mailto:${email}`}>
          {t('resendRegistrationEmailPage.checkYourInbox')}
        </a>
      }
    ></Result>
  );
};
