import { AuthServer } from './auth.server';
import { PublicConfirmEmailServer } from './public/public-confirm-email.server';
import { PublicForgottenPasswordServer } from './public/public-forgotten-password.server';
import { PublicOneTimeProductServer } from './public/public-one-time-product.server';
import { PublicPlanServer } from './public/public-plan.server';
import { PublicRegisterServer } from './public/public-register.server';
import { PublicResendRegistrationEmailServer } from './public/public-resend-registration-email.server';
import { PublicStripePaymentIntentServer } from './public/public-stripe-payment-intent.server';
import { PublicStripeSetupIntentServer } from './public/public-stripe-setup-intent.server';
import { PublicTokenIssuerServer } from './public/public-token-issuer.server';
import { PublicUserServer } from './public/public-user.server';

export class Server {
  static Auth = new AuthServer();
  static PublicTokenIssuer = new PublicTokenIssuerServer();
  static PublicForgottenPassword = new PublicForgottenPasswordServer();
  static PublicRegister = new PublicRegisterServer();
  static PublicResendRegistrationEmail = new PublicResendRegistrationEmailServer();
  static PublicPlan = new PublicPlanServer();
  static PublicOneTimeProduct = new PublicOneTimeProductServer();
  static PublicStripeSetupIntent = new PublicStripeSetupIntentServer();
  static PublicStripePaymentIntent = new PublicStripePaymentIntentServer();
  static PublicUser = new PublicUserServer();
  static PublicConfirmEmail = new PublicConfirmEmailServer();
}
