import axios from 'axios';
import { API_URL } from '../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../shared-types/error-res.dto';
import type { PostResendRegistrationEmailReqDto } from './req/post-resend-registration-email-req.dto';
import type { PostPublicResendRegistrationEmailResDto } from './res/post-public-resend-registration-email-res.dto';

export class PublicResendRegistrationEmailServer {
  private readonly API_PATH: string = `${API_URL}/public/resend/registration-email`;

  async post(data: PostResendRegistrationEmailReqDto): Promise<PostPublicResendRegistrationEmailResDto | ErrorResDto> {
    try {
      const response = await axios.post<PostPublicResendRegistrationEmailResDto>(this.API_PATH, data);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
