import axios from 'axios';
import { API_URL } from '../constants';
import { HandleAxiosErrorResDto, type ErrorResDto } from '../shared-types/error-res.dto';
import type { PostResDto } from '../shared-types/post-res.dto';
import type { PostPublicRegisterReqDto } from './req/post-public-register-req.dto';

export class PublicRegisterServer {
  private readonly API_PATH: string = `${API_URL}/public/register`;

  async post(data: PostPublicRegisterReqDto): Promise<PostResDto | ErrorResDto> {
    try {
      const response = await axios.post<PostResDto>(this.API_PATH, data);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
