import { Col, Row, Spin } from 'antd';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import './App.css';
import { BlackLogoImageComponent } from './components/image/black-logo-image.component';
import { ForgottenPasswordPage } from './pages/forgotten-password/forgotten-password.page';
import { UpdateForgottenPasswordPage } from './pages/forgotten-password/update-forgotten-password.page';
import { LoginPage } from './pages/login/login.page';
import { LogOutPage } from './pages/logout/logout.page';
import { PasswordUpdatePage } from './pages/password-update/password-update.page';
import { PostRegisterPage } from './pages/register/post-register.page';
import { PreRegisterPage } from './pages/register/pre-register.page';
import { RegisterPage } from './pages/register/register.page';
import { ResendRegistrationEmailPage } from './pages/resend-registration-email/resend-registration-email.page';

export const App = function App() {
  const { t } = useTranslation();

  return (
    <Router>
      <Row className="container min-vh-100 d-flex justify-content-center align-items-center">
        <Col md={16} className="mx-auto">
          <BlackLogoImageComponent />
          <div className="form-wrapper">
            <Suspense fallback={<Spin />}>
              <Routes>
                <Route key="/" path="/" element={<PreRegisterPage />} />
                <Route path="/post-registration" element={<PostRegisterPage />} />
                <Route path="/pre-registration" element={<PreRegisterPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgotten-password" element={<ForgottenPasswordPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/update-forgotten-password" element={<UpdateForgottenPasswordPage />} />
                <Route path="/resend-registration-email" element={<ResendRegistrationEmailPage />} />
                <Route path="/logout" element={<LogOutPage />} />
                <Route path="password-update" element={<PasswordUpdatePage />} />

                {/* <Route path="/confirm-register" element={<ConfirmRegister />} /> */}
              </Routes>
            </Suspense>
          </div>
        </Col>
      </Row>
    </Router>
  );
};
