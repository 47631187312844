import axios from 'axios';
import { API_URL } from '../constants';
import { HandleAxiosErrorResDto, type ErrorResDto } from '../shared-types/error-res.dto';
import { PatchResDto } from '../shared-types/patch-res.dto';
import { PatchPublicUserReqDto } from './req/patch-public-user-req.dto';

export class PublicUserServer {
  private readonly API_PATH: string = `${API_URL}/public/user`;

  async get(email: string): Promise<{ data: boolean } | ErrorResDto> {
    try {
      const response = await axios.get<{ data: boolean }>(`${this.API_PATH}?email=${email}`);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
  async patch(data: PatchPublicUserReqDto): Promise<PatchResDto | ErrorResDto> {
    try {
      const response = await axios.patch<PatchResDto>(`${this.API_PATH}`, data);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
