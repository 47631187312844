import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Card, Row } from 'antd';
import Meta from 'antd/es/card/Meta';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PublicPlanResDto } from '../../../api/public/res/public-plan-res.dto';

type Props = {
  plan: PublicPlanResDto & { isSelected: boolean };
  onClick: (plan: PublicPlanResDto) => void;
};

export const PlanComponent = function PlanComponent(props: Props) {
  const { plan } = props;
  const { t } = useTranslation();
  const translationPrefix = 'registerPage.components.plan';

  return (
    <Card
      className={`mb-2 plan-card ${plan.isSelected ? 'active-plan-card' : ''} `}
      title={plan.displayName}
      key={plan.id}
      headStyle={{
        backgroundColor: `${plan.color}`,
        minHeight: '100px',
        display: 'flex',
        justifyContent: 'center',
        color: '#fff',
      }}
    >
      {plan.isSelected ? (
        <>
          <div className="selected-plan-tag">
            <CheckCircleFilled style={{ fontSize: '2rem', color: '#a1cdea' }} />
          </div>
        </>
      ) : (
        ''
      )}
      <Meta
        title={
          new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(plan.amountInCents / 100) +
          ' + VAT / Sales Tax'
        }
        description={plan.description}
      />
      <Row className="mt-3" justify="space-between" align="middle">
        <div>{/* {t(`${translationPrefix}.numberOfUsersIds`)} {plan.numberOfUsers} */}</div>
        <div>
          <Link to={`purchase/${plan.id}`}>
            <Button
              style={{ backgroundColor: `${plan.color}`, color: '#fff', borderRadius: '4px' }}
              onClick={(e) => {
                e.preventDefault();
                props.onClick(plan);
              }}
            >
              {t(`${translationPrefix}.button.label`)}
            </Button>
          </Link>
        </div>
      </Row>
    </Card>
  );
};
