import { Result } from 'antd';
import { useCallback, useEffect } from 'react';
import { GetOrCreateStripeAsync } from '../../api/stripe/stripe.config';

export function PostRegisterPage() {
  const load = useCallback(async () => {
    const stripe = await GetOrCreateStripeAsync();
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret!).then(({ paymentIntent }) => {
      switch (paymentIntent!.status) {
        case 'succeeded':
          alert('Payment succeeded!');
          break;
        case 'processing':
          alert('Your payment is processing.');
          break;
        case 'requires_payment_method':
          alert('Your payment was not successful, please try again.');
          break;
        default:
          alert('Something went wrong.');
          break;
      }
    });
  }, []);
  useEffect(() => {
    load();
  }, [load]);
  return (
    <Result
      status="success"
      title={
        <>
          <div>Your order is NOT YET COMPLETE.</div>
          Check your email to finish Step 3.
        </>
      }
    />
  );
}
