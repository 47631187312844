import axios from 'axios';
import { API_URL } from '../constants';
import { HandleAxiosErrorResDto, type ErrorResDto } from '../shared-types/error-res.dto';
import type { PostResDto } from '../shared-types/post-res.dto';

export class PublicConfirmEmailServer {
  private readonly API_PATH: string = `${API_URL}/public/confirm/email`;

  async get(search: string): Promise<PostResDto | ErrorResDto> {
    try {
      const response = await axios.get<PostResDto>(`${this.API_PATH}${search}`);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
