import axios from 'axios';
import { API_URL } from '../constants';

import { ErrorResDto, HandleAxiosErrorResDto } from '../shared-types/error-res.dto';
import type { PatchResDto } from '../shared-types/patch-res.dto';
import type { PostResDto } from '../shared-types/post-res.dto';
import { PatchPublicForgottenPasswordReqDto } from './req/patch-public-forgotten-password-req.dto';
import { PostUserForgottenPasswordReqDto } from './req/post-public-forgotten-password-req.dto';

export class PublicForgottenPasswordServer {
  private readonly API_PATH: string = `${API_URL}/public/forgotten/password`;

  async post(data: PostUserForgottenPasswordReqDto): Promise<PostResDto | ErrorResDto> {
    try {
      const response = await axios.post<PostResDto>(this.API_PATH, data);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }

  async patch(data: PatchPublicForgottenPasswordReqDto): Promise<PatchResDto | ErrorResDto> {
    try {
      const response = await axios.patch<PatchResDto>(this.API_PATH, data);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
