import axios from 'axios';
import { API_URL } from '../constants';
import { HandleAxiosErrorResDto, type ErrorResDto } from '../shared-types/error-res.dto';
import type { PostPublicStripePaymentIntentReqDto } from './req/post-public-stripe-payment-intent-req.dto';
import type { GetPublicStripeSetupIntentResDto } from './res/get-public-stripe-payment-intent-res.dto';

export class PublicStripePaymentIntentServer {
  private readonly API_PATH: string = `${API_URL}/public/stripe/payment-intent`;

  async post(data: PostPublicStripePaymentIntentReqDto): Promise<GetPublicStripeSetupIntentResDto | ErrorResDto> {
    try {
      const response = await axios.post<GetPublicStripeSetupIntentResDto>(this.API_PATH, data);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
