export const USStates = {
  name: 'United States of America',
  code: 'US',
  states: [
    {
      name: 'Alabama',
      code: 'AL',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Alaska',
      code: 'AK',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Arizona',
      code: 'AZ',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Arkansas',
      code: 'AR',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'California',
      code: 'CA',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Colorado',
      code: 'CO',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Connecticut',
      code: 'CT',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Delaware',
      code: 'DE',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Florida',
      code: 'FL',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Georgia',
      code: 'GA',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Hawaii',
      code: 'HI',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Idaho',
      code: 'ID',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Illinois',
      code: 'IL',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Indiana',
      code: 'IN',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Iowa',
      code: 'IA',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Kansas',
      code: 'KS',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Kentucky',
      code: 'KY',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Louisiana',
      code: 'LA',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Maine',
      code: 'ME',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Maryland',
      code: 'MD',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Massachusetts',
      code: 'MA',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Michigan',
      code: 'MI',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Minnesota',
      code: 'MN',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Mississippi',
      code: 'MS',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Missouri',
      code: 'MO',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Montana',
      code: 'MT',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Nebraska',
      code: 'NE',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Nevada',
      code: 'NV',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'New Hampshire',
      code: 'NH',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'New Jersey',
      code: 'NJ',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'New Mexico',
      code: 'NM',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'New York',
      code: 'NY',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'North Carolina',
      code: 'NC',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'North Dakota',
      code: 'ND',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Ohio',
      code: 'OH',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Oklahoma',
      code: 'OK',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Oregon',
      code: 'OR',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Pennsylvania',
      code: 'PA',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Rhode Island',
      code: 'RI',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'South Carolina',
      code: 'SC',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'South Dakota',
      code: 'SD',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Tennessee',
      code: 'TN',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Texas',
      code: 'TX',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Utah',
      code: 'UT',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Vermont',
      code: 'VT',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Virginia',
      code: 'VA',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Washington',
      code: 'WA',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'West Virginia',
      code: 'WV',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Wisconsin',
      code: 'WI',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
    {
      name: 'Wyoming',
      code: 'WY',
      zipRegex: '^\\d{5}(-\\d{4})?$',
    },
  ],
};
export const CanadaStates = {
  name: 'Canada',
  code: 'CA',
  states: [
    {
      name: 'Alberta',
      code: 'AB',
      zipRegex: '^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$',
    },
    {
      name: 'British Columbia',
      code: 'BC',
      zipRegex: '^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$',
    },
    {
      name: 'Manitoba',
      code: 'MB',
      zipRegex: '^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$',
    },
    {
      name: 'New Brunswick',
      code: 'NB',
      zipRegex: '^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$',
    },
    {
      name: 'Newfoundland and Labrador',
      code: 'NL',
      zipRegex: '^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$',
    },
    {
      name: 'Northwest Territories',
      code: 'NT',
      zipRegex: '^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$',
    },
    {
      name: 'Nova Scotia',
      code: 'NS',
      zipRegex: '^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$',
    },
    {
      name: 'Nunavut',
      code: 'NU',
      zipRegex: '^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$',
    },
    {
      name: 'Ontario',
      code: 'ON',
      zipRegex: '^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$',
    },
    {
      name: 'Prince Edward Island',
      code: 'PE',
      zipRegex: '^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$',
    },
    {
      name: 'Quebec',
      code: 'QC',
      zipRegex: '^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$',
    },
    {
      name: 'Saskatchewan',
      code: 'SK',
      zipRegex: '^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$',
    },
    {
      name: 'Yukon',
      code: 'YT',
      zipRegex: '^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$',
    },
  ],
};
export const MexicoStates = {
  name: 'Mexico',
  code: 'MX',
  states: [
    {
      name: 'Aguascalientes',
      code: 'AGU',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Baja California',
      code: 'BCN',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Baja California Sur',
      code: 'BCS',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Campeche',
      code: 'CAM',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Chiapas',
      code: 'CHP',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Chihuahua',
      code: 'CHH',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Ciudad de México',
      code: 'CDMX',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Coahuila',
      code: 'COA',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Colima',
      code: 'COL',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Durango',
      code: 'DUR',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Estado de México',
      code: 'MEX',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Guanajuato',
      code: 'GUA',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Guerrero',
      code: 'GRO',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Hidalgo',
      code: 'HID',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Jalisco',
      code: 'JAL',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Michoacán',
      code: 'MIC',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Morelos',
      code: 'MOR',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Nayarit',
      code: 'NAY',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Nuevo León',
      code: 'NLE',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Oaxaca',
      code: 'OAX',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Puebla',
      code: 'PUE',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Querétaro',
      code: 'QUE',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Quintana Roo',
      code: 'ROO',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'San Luis Potosí',
      code: 'SLP',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Sinaloa',
      code: 'SIN',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Sonora',
      code: 'SON',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Tabasco',
      code: 'TAB',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Tamaulipas',
      code: 'TAM',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Tlaxcala',
      code: 'TLA',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Veracruz',
      code: 'VER',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Yucatán',
      code: 'YUC',
      zipRegex: '^\\d{5}$',
    },
    {
      name: 'Zacatecas',
      code: 'ZAC',
      zipRegex: '^\\d{5}$',
    },
  ],
};
export const BrazilStates = {
  country: 'Brazil',
  code: 'BR',
  states: [
    {
      name: 'Acre',
      code: 'AC',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Alagoas',
      code: 'AL',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Amapá',
      code: 'AP',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Amazonas',
      code: 'AM',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Bahia',
      code: 'BA',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Ceará',
      code: 'CE',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Distrito Federal',
      code: 'DF',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Espírito Santo',
      code: 'ES',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Goiás',
      code: 'GO',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Maranhão',
      code: 'MA',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Mato Grosso',
      code: 'MT',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Mato Grosso do Sul',
      code: 'MS',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Minas Gerais',
      code: 'MG',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Pará',
      code: 'PA',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Paraíba',
      code: 'PB',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Paraná',
      code: 'PR',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Pernambuco',
      code: 'PE',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Piauí',
      code: 'PI',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Rio de Janeiro',
      code: 'RJ',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Rio Grande do Norte',
      code: 'RN',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Rio Grande do Sul',
      code: 'RS',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Rondônia',
      code: 'RO',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Roraima',
      code: 'RR',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Santa Catarina',
      code: 'SC',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'São Paulo',
      code: 'SP',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Sergipe',
      code: 'SE',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
    {
      name: 'Tocantins',
      code: 'TO',
      zipRegex: '^\\d{5}-\\d{3}$',
    },
  ],
};
export const AustraliaStates = {
  code: 'AU',
  country: 'Australia',
  states: [
    {
      name: 'Australian Capital Territory',
      code: 'ACT',
      zipRegex: '^\\d{4}$',
    },
    {
      name: 'New South Wales',
      code: 'NSW',
      zipRegex: '^\\d{4}$',
    },
    {
      name: 'Northern Territory',
      code: 'NT',
      zipRegex: '^\\d{4}$',
    },
    {
      name: 'Queensland',
      code: 'QLD',
      zipRegex: '^\\d{4}$',
    },
    {
      name: 'South Australia',
      code: 'SA',
      zipRegex: '^\\d{4}$',
    },
    {
      name: 'Tasmania',
      code: 'TAS',
      zipRegex: '^\\d{4}$',
    },
    {
      name: 'Victoria',
      code: 'VIC',
      zipRegex: '^\\d{4}$',
    },
    {
      name: 'Western Australia',
      code: 'WA',
      zipRegex: '^\\d{4}$',
    },
  ],
};
export const IndiaStates = {
  country: 'India',
  code: 'IN',

  states: [
    {
      name: 'Andaman and Nicobar Islands',
      code: 'AN',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Andhra Pradesh',
      code: 'AP',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Arunachal Pradesh',
      code: 'AR',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Assam',
      code: 'AS',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Bihar',
      code: 'BR',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Chandigarh',
      code: 'CH',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Chhattisgarh',
      code: 'CG',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Dadra and Nagar Haveli and Daman and Diu',
      code: 'DD',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Delhi',
      code: 'DL',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Goa',
      code: 'GA',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Gujarat',
      code: 'GJ',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Haryana',
      code: 'HR',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Himachal Pradesh',
      code: 'HP',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Jammu and Kashmir',
      code: 'JK',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Jharkhand',
      code: 'JH',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Karnataka',
      code: 'KA',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Kerala',
      code: 'KL',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Ladakh',
      code: 'LA',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Lakshadweep',
      code: 'LD',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Madhya Pradesh',
      code: 'MP',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Maharashtra',
      code: 'MH',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Manipur',
      code: 'MN',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Meghalaya',
      code: 'ML',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Mizoram',
      code: 'MZ',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Nagaland',
      code: 'NL',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Odisha',
      code: 'OR',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Puducherry',
      code: 'PY',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Punjab',
      code: 'PB',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Rajasthan',
      code: 'RJ',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Sikkim',
      code: 'SK',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Tamil Nadu',
      code: 'TN',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Telangana',
      code: 'TG',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Tripura',
      code: 'TR',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Uttar Pradesh',
      code: 'UP',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Uttarakhand',
      code: 'UK',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'West Bengal',
      code: 'WB',
      zipRegex: '^\\d{6}$',
    },
  ],
};
export const ChinaStates = {
  country: 'China',
  code: 'CN',
  states: [
    {
      name: 'Anhui',
      code: 'AH',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Beijing',
      code: 'BJ',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Chongqing',
      code: 'CQ',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Fujian',
      code: 'FJ',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Gansu',
      code: 'GS',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Guangdong',
      code: 'GD',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Guangxi',
      code: 'GX',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Guizhou',
      code: 'GZ',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Hainan',
      code: 'HI',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Hebei',
      code: 'HE',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Heilongjiang',
      code: 'HL',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Henan',
      code: 'HA',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Hong Kong',
      code: 'HK',
      zipRegex: '^(99907[78]\\d{1}|9990[0-9][1-9]|999[1-9][0-9]{2}|99[1-9][0-9]{3}|9[1-9][0-9]{4}|[1-9][0-9]{5})$',
    },
    {
      name: 'Hubei',
      code: 'HB',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Hunan',
      code: 'HN',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Inner Mongolia',
      code: 'NM',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Jiangsu',
      code: 'JS',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Jiangxi',
      code: 'JX',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Jilin',
      code: 'JL',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Liaoning',
      code: 'LN',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Macau',
      code: 'MO',
      zipRegex: '^999078$',
    },
    {
      name: 'Ningxia',
      code: 'NX',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Qinghai',
      code: 'QH',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Shaanxi',
      code: 'SN',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Shandong',
      code: 'SD',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Shanghai',
      code: 'SH',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Shanxi',
      code: 'SX',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Sichuan',
      code: 'SC',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Taiwan',
      code: 'TW',
      zipRegex: '^\\d{3}(\\d{2})?$',
    },
    {
      name: 'Tibet',
      code: 'XZ',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Xinjiang',
      code: 'XJ',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Yunnan',
      code: 'YN',
      zipRegex: '^\\d{6}$',
    },
    {
      name: 'Zhejiang',
      code: 'ZJ',
      zipRegex: '^\\d{6}$',
    },
  ],
};
export const NigeriaStates = {
  country: 'Nigeria',
  code: 'NG',

  states: [
    {
      name: 'Abia',
      code: 'AB',
      zipRegex: '^440\\d{3}$',
    },
    {
      name: 'Adamawa',
      code: 'AD',
      zipRegex: '^640\\d{3}$',
    },
    {
      name: 'Akwa Ibom',
      code: 'AK',
      zipRegex: '^520\\d{3}$',
    },
    {
      name: 'Anambra',
      code: 'AN',
      zipRegex: '^420\\d{3}$',
    },
    {
      name: 'Bauchi',
      code: 'BA',
      zipRegex: '^740\\d{3}$',
    },
    {
      name: 'Bayelsa',
      code: 'BY',
      zipRegex: '^561\\d{3}$',
    },
    {
      name: 'Benue',
      code: 'BE',
      zipRegex: '^970\\d{3}$',
    },
    {
      name: 'Borno',
      code: 'BO',
      zipRegex: '^600\\d{3}$',
    },
    {
      name: 'Cross River',
      code: 'CR',
      zipRegex: '^540\\d{3}$',
    },
    {
      name: 'Delta',
      code: 'DE',
      zipRegex: '^332\\d{3}$',
    },
    {
      name: 'Ebonyi',
      code: 'EB',
      zipRegex: '^840\\d{3}$',
    },
    {
      name: 'Edo',
      code: 'ED',
      zipRegex: '^300\\d{3}$',
    },
    {
      name: 'Ekiti',
      code: 'EK',
      zipRegex: '^360\\d{3}$',
    },
    {
      name: 'Enugu',
      code: 'EN',
      zipRegex: '^400\\d{3}$',
    },
    {
      name: 'Federal Capital Territory',
      code: 'FC',
      zipRegex: '^900\\d{3}$',
    },
    {
      name: 'Gombe',
      code: 'GO',
      zipRegex: '^760\\d{3}$',
    },
    {
      name: 'Imo',
      code: 'IM',
      zipRegex: '^460\\d{3}$',
    },
    {
      name: 'Jigawa',
      code: 'JI',
      zipRegex: '^720\\d{3}$',
    },
    {
      name: 'Kaduna',
      code: 'KD',
      zipRegex: '^700\\d{3}$',
    },
    {
      name: 'Kano',
      code: 'KN',
      zipRegex: '^800\\d{3}$',
    },
    {
      name: 'Katsina',
      code: 'KT',
      zipRegex: '^820\\d{3}$',
    },
    {
      name: 'Kebbi',
      code: 'KE',
      zipRegex: '^860\\d{3}$',
    },
    {
      name: 'Kogi',
      code: 'KO',
      zipRegex: '^260\\d{3}$',
    },
    {
      name: 'Kwara',
      code: 'KW',
      zipRegex: '^240\\d{3}$',
    },
    {
      name: 'Lagos',
      code: 'LA',
      zipRegex: '^100\\d{3}$',
    },
    {
      name: 'Nasarawa',
      code: 'NA',
      zipRegex: '^962\\d{3}$',
    },
    {
      name: 'Niger',
      code: 'NI',
      zipRegex: '^920\\d{3}$',
    },
    {
      name: 'Ogun',
      code: 'OG',
      zipRegex: '^110\\d{3}$',
    },
    {
      name: 'Ondo',
      code: 'ON',
      zipRegex: '^340\\d{3}$',
    },
    {
      name: 'Osun',
      code: 'OS',
      zipRegex: '^230\\d{3}$',
    },
    {
      name: 'Oyo',
      code: 'OY',
      zipRegex: '^200\\d{3}$',
    },
    {
      name: 'Plateau',
      code: 'PL',
      zipRegex: '^930\\d{3}$',
    },
    {
      name: 'Rivers',
      code: 'RI',
      zipRegex: '^500\\d{3}$',
    },
    {
      name: 'Sokoto',
      code: 'SO',
      zipRegex: '^840\\d{3}$',
    },
    {
      name: 'Taraba',
      code: 'TA',
      zipRegex: '^660\\d{3}$',
    },
    {
      name: 'Yobe',
      code: 'YO',
      zipRegex: '^320\\d{3}$',
    },
    {
      name: 'Zamfara',
      code: 'ZA',
      zipRegex: '^860\\d{3}$',
    },
  ],
};
export const ArgentinaStates = {
  country: 'Argentina',
  code: 'AR',
  states: [
    {
      name: 'Buenos Aires',
      code: 'B',
      zipRegex: '^B[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Catamarca',
      code: 'K',
      zipRegex: '^K[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Chaco',
      code: 'H',
      zipRegex: '^H[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Chubut',
      code: 'U',
      zipRegex: '^U[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Ciudad Autónoma de Buenos Aires',
      code: 'C',
      zipRegex: '^C[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Córdoba',
      code: 'X',
      zipRegex: '^X[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Corrientes',
      code: 'W',
      zipRegex: '^W[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Entre Ríos',
      code: 'E',
      zipRegex: '^E[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Formosa',
      code: 'P',
      zipRegex: '^P[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Jujuy',
      code: 'Y',
      zipRegex: '^Y[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'La Pampa',
      code: 'L',
      zipRegex: '^L[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'La Rioja',
      code: 'F',
      zipRegex: '^F[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Mendoza',
      code: 'M',
      zipRegex: '^M[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Misiones',
      code: 'N',
      zipRegex: '^N[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Neuquén',
      code: 'Q',
      zipRegex: '^Q[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Río Negro',
      code: 'R',
      zipRegex: '^R[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Salta',
      code: 'A',
      zipRegex: '^A[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'San Juan',
      code: 'J',
      zipRegex: '^J[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'San Luis',
      code: 'D',
      zipRegex: '^D[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Santa Cruz',
      code: 'Z',
      zipRegex: '^Z[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Santa Fe',
      code: 'S',
      zipRegex: '^S[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Santiago del Estero',
      code: 'G',
      zipRegex: '^G[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Tierra del Fuego',
      code: 'V',
      zipRegex: '^V[0-9]{4}[A-Z]{3}$',
    },
    {
      name: 'Tucumán',
      code: 'T',
      zipRegex: '^T[0-9]{4}[A-Z]{3}$',
    },
  ],
};
export const IndonesiaStates = {
  country: 'Indonesia',
  code: 'ID',
  states: [
    {
      name: 'Aceh',
      code: 'AC',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Bali',
      code: 'BA',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Banten',
      code: 'BT',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Bengkulu',
      code: 'BE',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Gorontalo',
      code: 'GO',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Jakarta',
      code: 'JK',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Jambi',
      code: 'JA',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Jawa Barat',
      code: 'JB',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Jawa Tengah',
      code: 'JT',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Jawa Timur',
      code: 'JI',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Kalimantan Barat',
      code: 'KB',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Kalimantan Selatan',
      code: 'KS',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Kalimantan Tengah',
      code: 'KT',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Kalimantan Timur',
      code: 'KI',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Kalimantan Utara',
      code: 'KU',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Kepulauan Bangka Belitung',
      code: 'BB',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Kepulauan Riau',
      code: 'KR',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Lampung',
      code: 'LA',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Maluku',
      code: 'MA',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Maluku Utara',
      code: 'MU',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'North Maluku',
      shortCode: 'MU',
      zipRegex: '97\\d{4}',
    },
    {
      name: 'North Sulawesi',
      shortCode: 'SA',
      zipRegex: '95\\d{4}',
    },
    {
      name: 'West Sulawesi',
      shortCode: 'SR',
      zipRegex: '90\\d{4}',
    },
    {
      name: 'Central Sulawesi',
      shortCode: 'ST',
      zipRegex: '94\\d{4}',
    },
    {
      name: 'Southeast Sulawesi',
      shortCode: 'SG',
      zipRegex: '93\\d{4}',
    },
    {
      name: 'South Sulawesi',
      shortCode: 'SN',
      zipRegex: '90\\d{4}|91\\d{4}',
    },
    {
      name: 'Gorontalo',
      shortCode: 'GO',
      zipRegex: '96\\d{4}',
    },
    {
      name: 'Papua',
      shortCode: 'PA',
      zipRegex: '99\\d{4}',
    },
    {
      name: 'West Papua',
      shortCode: 'PB',
      zipRegex: '98\\d{4}',
    },
  ],
};
export const MalaysiaStates = {
  country: 'Malaysia',
  code: 'MY',
  states: [
    {
      name: 'Johor',
      shortCode: '01',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Kedah',
      shortCode: '02',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Kelantan',
      shortCode: '03',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Melaka',
      shortCode: '04',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Negeri Sembilan',
      shortCode: '05',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Pahang',
      shortCode: '06',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Perak',
      shortCode: '08',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Perlis',
      shortCode: '09',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Pulau Pinang',
      shortCode: '07',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Sabah',
      shortCode: '12',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Sarawak',
      shortCode: '13',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Selangor',
      shortCode: '10',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Terengganu',
      shortCode: '11',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Federal Territory of Kuala Lumpur',
      shortCode: '14',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Federal Territory of Labuan',
      shortCode: '15',
      zipRegex: '^[0-9]{5}$',
    },
    {
      name: 'Federal Territory of Putrajaya',
      shortCode: '16',
      zipRegex: '^[0-9]{5}$',
    },
  ],
};
export const PhilippinesStates = {
  country: 'Philippines',
  code: 'PH',
  states: [
    {
      name: 'Abra',
      short_code: 'ABR',
      zipRegex: '^28\\d{3}$',
    },
    {
      name: 'Agusan del Norte',
      short_code: 'AGN',
      zipRegex: '^860\\d{2}$',
    },
    {
      name: 'Agusan del Sur',
      short_code: 'AGS',
      zipRegex: '^850\\d{2}$',
    },
    {
      name: 'Aklan',
      short_code: 'AKL',
      zipRegex: '^56\\d{3}$',
    },
    {
      name: 'Albay',
      short_code: 'ALB',
      zipRegex: '^45\\d{3}$',
    },
    {
      name: 'Antique',
      short_code: 'ANT',
      zipRegex: '^57\\d{3}$',
    },
    {
      name: 'Apayao',
      short_code: 'APA',
      zipRegex: '^37\\d{3}$',
    },
    {
      name: 'Aurora',
      short_code: 'AUR',
      zipRegex: '^32\\d{3}$',
    },
    {
      name: 'Basilan',
      short_code: 'BAS',
      zipRegex: '^730\\d{2}$',
    },
    {
      name: 'Bataan',
      short_code: 'BAN',
      zipRegex: '^21\\d{3}$',
    },
    {
      name: 'Batanes',
      short_code: 'BTN',
      zipRegex: '^390\\d{2}$',
    },
    {
      name: 'Batangas',
      short_code: 'BTG',
      zipRegex: '^42\\d{3}$',
    },
    {
      name: 'Benguet',
      short_code: 'BEN',
      zipRegex: '^26\\d{3}$',
    },
    {
      name: 'Biliran',
      short_code: 'BIL',
      zipRegex: '^65\\d{3}$',
    },
    {
      name: 'Bohol',
      short_code: 'BOH',
      zipRegex: '^63\\d{3}$',
    },
    {
      name: 'Bukidnon',
      short_code: 'BUK',
      zipRegex: '^87\\d{3}$',
    },
    {
      name: 'Bulacan',
      short_code: 'BUL',
      zipRegex: '^30\\d{3}$',
    },
    {
      name: 'Cagayan',
      short_code: 'CAG',
      zipRegex: '^35\\d{3}$',
    },
    {
      name: 'Camarines Norte',
      short_code: 'CAN',
      zipRegex: '^460\\d{2}$',
    },
    {
      name: 'Camarines Sur',
      short_code: 'CAS',
      zipRegex: '^440\\d{2}$',
    },
  ],
};
export const SwitzerlandStates = {
  country: 'Switzerland',
  code: 'CH',
  zipCodeRegex: '^[0-9]{4}$',
};

export const GetStatesForCountry = (countryCode: string): any => {
  if (countryCode === USStates.code) {
    return USStates;
  }
  if (countryCode === SwitzerlandStates.code) {
    return SwitzerlandStates;
  }
  if (countryCode === IndiaStates.code) {
    return IndiaStates;
  }
  if (countryCode === MexicoStates.code) {
    return MexicoStates;
  }
  return [];
};
