import { Radio, RadioChangeEvent, Space } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FloatInput } from '../../../components/input/float-input';
const translationPrefix = 'registerPage';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
}
export const ReferredByComponent = function ReferredByComponent({ value = {}, onChange }: Props) {
  const { t } = useTranslation();
  const [radioValue, setValue] = useState(1);

  const onChangeProxy = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    if (e.target.value === 2) {
      onChange?.('None');
    } else {
      onChange?.(null);
    }
  };
  return (
    <>
      <div>{t(`${translationPrefix}.form.referredBy.label`)}</div>
      <Radio.Group onChange={onChangeProxy} value={radioValue}>
        <Space direction="horizontal">
          <Radio value={1}>
            <FloatInput
              placeholder={'Referral / Promo'}
              disabled={radioValue !== 1}
              type="string"
              required={radioValue === 1}
              onChange={(x) => {
                const value = x.target.value;
                onChange?.(value);
              }}
            />
          </Radio>
          <Radio value={2}>None</Radio>
        </Space>
      </Radio.Group>
    </>
  );
};
